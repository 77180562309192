/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageApprovalResponse,
  ListApprovalsParams,
  PartnerBankProposalResponse,
  ProductProposalResponse,
  RateProposalResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listApprovals = (
  params?: ListApprovalsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageApprovalResponse>({
    url: `/operations/approvals`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListApprovalsQueryKey = (params?: ListApprovalsParams) => {
  return [`/operations/approvals`, ...(params ? [params] : [])] as const;
};

export const getListApprovalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listApprovals>>,
  TError = unknown
>(
  params?: ListApprovalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listApprovals>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListApprovalsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listApprovals>>> = ({
    signal,
  }) => listApprovals(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listApprovals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListApprovalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listApprovals>>
>;
export type ListApprovalsQueryError = unknown;

export const useListApprovals = <
  TData = Awaited<ReturnType<typeof listApprovals>>,
  TError = unknown
>(
  params?: ListApprovalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listApprovals>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListApprovalsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchBankProposal = (
  proposalUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PartnerBankProposalResponse>({
    url: `/operations/approvals/banks/${proposalUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchBankProposalQueryKey = (proposalUid: string) => {
  return [`/operations/approvals/banks/${proposalUid}`] as const;
};

export const getFetchBankProposalQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchBankProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBankProposal>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchBankProposalQueryKey(proposalUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchBankProposal>>> =
    ({ signal }) => fetchBankProposal(proposalUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proposalUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchBankProposal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchBankProposalQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchBankProposal>>
>;
export type FetchBankProposalQueryError = unknown;

export const useFetchBankProposal = <
  TData = Awaited<ReturnType<typeof fetchBankProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBankProposal>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchBankProposalQueryOptions(proposalUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchProductProposal = (
  proposalUid: string,
  signal?: AbortSignal
) => {
  return customInstance<ProductProposalResponse>({
    url: `/operations/approvals/products/${proposalUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchProductProposalQueryKey = (proposalUid: string) => {
  return [`/operations/approvals/products/${proposalUid}`] as const;
};

export const getFetchProductProposalQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProductProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductProposal>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchProductProposalQueryKey(proposalUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchProductProposal>>
  > = ({ signal }) => fetchProductProposal(proposalUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proposalUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProductProposal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProductProposalQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProductProposal>>
>;
export type FetchProductProposalQueryError = unknown;

export const useFetchProductProposal = <
  TData = Awaited<ReturnType<typeof fetchProductProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductProposal>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProductProposalQueryOptions(
    proposalUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchRateProposal = (
  proposalUid: string,
  signal?: AbortSignal
) => {
  return customInstance<RateProposalResponse>({
    url: `/operations/approvals/rate/${proposalUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchRateProposalQueryKey = (proposalUid: string) => {
  return [`/operations/approvals/rate/${proposalUid}`] as const;
};

export const getFetchRateProposalQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchRateProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchRateProposal>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchRateProposalQueryKey(proposalUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchRateProposal>>> =
    ({ signal }) => fetchRateProposal(proposalUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proposalUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchRateProposal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchRateProposalQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchRateProposal>>
>;
export type FetchRateProposalQueryError = unknown;

export const useFetchRateProposal = <
  TData = Awaited<ReturnType<typeof fetchRateProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchRateProposal>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchRateProposalQueryOptions(proposalUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const acceptApproval = (approvalUid: string) => {
  return customInstance<string>({
    url: `/operations/approvals/${approvalUid}/accept`,
    method: 'POST',
  });
};

export const getAcceptApprovalMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptApproval>>,
    TError,
    { approvalUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptApproval>>,
  TError,
  { approvalUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptApproval>>,
    { approvalUid: string }
  > = (props) => {
    const { approvalUid } = props ?? {};

    return acceptApproval(approvalUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptApprovalMutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptApproval>>
>;

export type AcceptApprovalMutationError = unknown;

export const useAcceptApproval = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptApproval>>,
    TError,
    { approvalUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof acceptApproval>>,
  TError,
  { approvalUid: string },
  TContext
> => {
  const mutationOptions = getAcceptApprovalMutationOptions(options);

  return useMutation(mutationOptions);
};
export const rejectApproval = (approvalUid: string) => {
  return customInstance<void>({
    url: `/operations/approvals/${approvalUid}/reject`,
    method: 'POST',
  });
};

export const getRejectApprovalMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectApproval>>,
    TError,
    { approvalUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rejectApproval>>,
  TError,
  { approvalUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rejectApproval>>,
    { approvalUid: string }
  > = (props) => {
    const { approvalUid } = props ?? {};

    return rejectApproval(approvalUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type RejectApprovalMutationResult = NonNullable<
  Awaited<ReturnType<typeof rejectApproval>>
>;

export type RejectApprovalMutationError = unknown;

export const useRejectApproval = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectApproval>>,
    TError,
    { approvalUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof rejectApproval>>,
  TError,
  { approvalUid: string },
  TContext
> => {
  const mutationOptions = getRejectApprovalMutationOptions(options);

  return useMutation(mutationOptions);
};
