/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PartnerPlatformIntegrationType =
  typeof PartnerPlatformIntegrationType[keyof typeof PartnerPlatformIntegrationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerPlatformIntegrationType = {
  FILE_BASED: 'FILE_BASED',
  EXTERNAL_API: 'EXTERNAL_API',
} as const;
