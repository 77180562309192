/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  DailyDepositsResponse,
  GetDailyDepositsParams,
  GetStatisticsParams,
  StatisticsResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getStatistics = (
  params: GetStatisticsParams,
  signal?: AbortSignal
) => {
  return customInstance<StatisticsResponse>({
    url: `/operations/statistics`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetStatisticsQueryKey = (params: GetStatisticsParams) => {
  return [`/operations/statistics`, ...(params ? [params] : [])] as const;
};

export const getGetStatisticsQueryOptions = <
  TData = Awaited<ReturnType<typeof getStatistics>>,
  TError = unknown
>(
  params: GetStatisticsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStatistics>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStatisticsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatistics>>> = ({
    signal,
  }) => getStatistics(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStatistics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetStatisticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStatistics>>
>;
export type GetStatisticsQueryError = unknown;

export const useGetStatistics = <
  TData = Awaited<ReturnType<typeof getStatistics>>,
  TError = unknown
>(
  params: GetStatisticsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStatistics>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStatisticsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDailyDeposits = (
  params?: GetDailyDepositsParams,
  signal?: AbortSignal
) => {
  return customInstance<DailyDepositsResponse[]>({
    url: `/operations/statistics/deposits`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetDailyDepositsQueryKey = (
  params?: GetDailyDepositsParams
) => {
  return [
    `/operations/statistics/deposits`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetDailyDepositsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDailyDeposits>>,
  TError = unknown
>(
  params?: GetDailyDepositsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDailyDeposits>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDailyDepositsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDailyDeposits>>> =
    ({ signal }) => getDailyDeposits(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDailyDeposits>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDailyDepositsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDailyDeposits>>
>;
export type GetDailyDepositsQueryError = unknown;

export const useGetDailyDeposits = <
  TData = Awaited<ReturnType<typeof getDailyDeposits>>,
  TError = unknown
>(
  params?: GetDailyDepositsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDailyDeposits>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDailyDepositsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
