/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

/**
 * The reason for the customer to break their deposit
 */
export type DepositBreakType =
  typeof DepositBreakType[keyof typeof DepositBreakType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepositBreakType = {
  DEATH: 'DEATH',
  FINANCIAL_HARDSHIP: 'FINANCIAL_HARDSHIP',
  OTHER: 'OTHER',
} as const;
