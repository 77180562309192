/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  AccountOwnerDetailResponse,
  AccountStatementLineResponse,
  AccountStatementLineStatusUpdateRequest,
  AccountStatementLineUpdateRequest,
  ConnectorType,
  InternalCurrencyCode,
  PartnerType,
  RealAccountType,
  UKVirtualAccountResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const fetchUKVirtualAccountResponse = (
  accountOwnerUid: string,
  connectorType: ConnectorType,
  signal?: AbortSignal
) => {
  return customInstance<UKVirtualAccountResponse>({
    url: `/operations/account/own/owner/${accountOwnerUid}/${connectorType}`,
    method: 'GET',
    signal,
  });
};

export const getFetchUKVirtualAccountResponseQueryKey = (
  accountOwnerUid: string,
  connectorType: ConnectorType
) => {
  return [
    `/operations/account/own/owner/${accountOwnerUid}/${connectorType}`,
  ] as const;
};

export const getFetchUKVirtualAccountResponseQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchUKVirtualAccountResponse>>,
  TError = unknown
>(
  accountOwnerUid: string,
  connectorType: ConnectorType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchUKVirtualAccountResponse>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchUKVirtualAccountResponseQueryKey(accountOwnerUid, connectorType);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchUKVirtualAccountResponse>>
  > = ({ signal }) =>
    fetchUKVirtualAccountResponse(accountOwnerUid, connectorType, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(accountOwnerUid && connectorType),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchUKVirtualAccountResponse>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchUKVirtualAccountResponseQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchUKVirtualAccountResponse>>
>;
export type FetchUKVirtualAccountResponseQueryError = unknown;

export const useFetchUKVirtualAccountResponse = <
  TData = Awaited<ReturnType<typeof fetchUKVirtualAccountResponse>>,
  TError = unknown
>(
  accountOwnerUid: string,
  connectorType: ConnectorType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchUKVirtualAccountResponse>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchUKVirtualAccountResponseQueryOptions(
    accountOwnerUid,
    connectorType,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchUKRealAccountResponse = (
  connectorType: ConnectorType,
  realAccountType: RealAccountType,
  currencyCode: InternalCurrencyCode,
  signal?: AbortSignal
) => {
  return customInstance<UKVirtualAccountResponse>({
    url: `/operations/account/own/${connectorType}/${realAccountType}/${currencyCode}`,
    method: 'GET',
    signal,
  });
};

export const getFetchUKRealAccountResponseQueryKey = (
  connectorType: ConnectorType,
  realAccountType: RealAccountType,
  currencyCode: InternalCurrencyCode
) => {
  return [
    `/operations/account/own/${connectorType}/${realAccountType}/${currencyCode}`,
  ] as const;
};

export const getFetchUKRealAccountResponseQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchUKRealAccountResponse>>,
  TError = unknown
>(
  connectorType: ConnectorType,
  realAccountType: RealAccountType,
  currencyCode: InternalCurrencyCode,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchUKRealAccountResponse>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchUKRealAccountResponseQueryKey(
      connectorType,
      realAccountType,
      currencyCode
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchUKRealAccountResponse>>
  > = ({ signal }) =>
    fetchUKRealAccountResponse(
      connectorType,
      realAccountType,
      currencyCode,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(connectorType && realAccountType && currencyCode),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchUKRealAccountResponse>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchUKRealAccountResponseQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchUKRealAccountResponse>>
>;
export type FetchUKRealAccountResponseQueryError = unknown;

export const useFetchUKRealAccountResponse = <
  TData = Awaited<ReturnType<typeof fetchUKRealAccountResponse>>,
  TError = unknown
>(
  connectorType: ConnectorType,
  realAccountType: RealAccountType,
  currencyCode: InternalCurrencyCode,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchUKRealAccountResponse>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchUKRealAccountResponseQueryOptions(
    connectorType,
    realAccountType,
    currencyCode,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCustomerAccountOwners = (
  partnerType: PartnerType,
  signal?: AbortSignal
) => {
  return customInstance<AccountOwnerDetailResponse[]>({
    url: `/operations/account/own/${partnerType}`,
    method: 'GET',
    signal,
  });
};

export const getGetCustomerAccountOwnersQueryKey = (
  partnerType: PartnerType
) => {
  return [`/operations/account/own/${partnerType}`] as const;
};

export const getGetCustomerAccountOwnersQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerAccountOwners>>,
  TError = unknown
>(
  partnerType: PartnerType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerAccountOwners>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCustomerAccountOwnersQueryKey(partnerType);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerAccountOwners>>
  > = ({ signal }) => getCustomerAccountOwners(partnerType, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!partnerType,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerAccountOwners>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerAccountOwnersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerAccountOwners>>
>;
export type GetCustomerAccountOwnersQueryError = unknown;

export const useGetCustomerAccountOwners = <
  TData = Awaited<ReturnType<typeof getCustomerAccountOwners>>,
  TError = unknown
>(
  partnerType: PartnerType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerAccountOwners>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerAccountOwnersQueryOptions(
    partnerType,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchStatementLinesWithExceptions = (signal?: AbortSignal) => {
  return customInstance<AccountStatementLineResponse[]>({
    url: `/operations/account/statement/exceptions`,
    method: 'GET',
    signal,
  });
};

export const getFetchStatementLinesWithExceptionsQueryKey = () => {
  return [`/operations/account/statement/exceptions`] as const;
};

export const getFetchStatementLinesWithExceptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchStatementLinesWithExceptions>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchStatementLinesWithExceptions>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchStatementLinesWithExceptionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchStatementLinesWithExceptions>>
  > = ({ signal }) => fetchStatementLinesWithExceptions(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchStatementLinesWithExceptions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchStatementLinesWithExceptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchStatementLinesWithExceptions>>
>;
export type FetchStatementLinesWithExceptionsQueryError = unknown;

export const useFetchStatementLinesWithExceptions = <
  TData = Awaited<ReturnType<typeof fetchStatementLinesWithExceptions>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchStatementLinesWithExceptions>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getFetchStatementLinesWithExceptionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateStatementLine = (
  accountStatementLineUid: string,
  accountStatementLineUpdateRequest: AccountStatementLineUpdateRequest
) => {
  return customInstance<void>({
    url: `/operations/account/statement/${accountStatementLineUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: accountStatementLineUpdateRequest,
  });
};

export const getUpdateStatementLineMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStatementLine>>,
    TError,
    {
      accountStatementLineUid: string;
      data: AccountStatementLineUpdateRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStatementLine>>,
  TError,
  { accountStatementLineUid: string; data: AccountStatementLineUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStatementLine>>,
    { accountStatementLineUid: string; data: AccountStatementLineUpdateRequest }
  > = (props) => {
    const { accountStatementLineUid, data } = props ?? {};

    return updateStatementLine(accountStatementLineUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateStatementLineMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStatementLine>>
>;
export type UpdateStatementLineMutationBody = AccountStatementLineUpdateRequest;
export type UpdateStatementLineMutationError = unknown;

export const useUpdateStatementLine = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStatementLine>>,
    TError,
    {
      accountStatementLineUid: string;
      data: AccountStatementLineUpdateRequest;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateStatementLine>>,
  TError,
  { accountStatementLineUid: string; data: AccountStatementLineUpdateRequest },
  TContext
> => {
  const mutationOptions = getUpdateStatementLineMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateStatementLineStatus = (
  accountStatementLineUid: string,
  accountStatementLineStatusUpdateRequest: AccountStatementLineStatusUpdateRequest
) => {
  return customInstance<void>({
    url: `/operations/account/statement/${accountStatementLineUid}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: accountStatementLineStatusUpdateRequest,
  });
};

export const getUpdateStatementLineStatusMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStatementLineStatus>>,
    TError,
    {
      accountStatementLineUid: string;
      data: AccountStatementLineStatusUpdateRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStatementLineStatus>>,
  TError,
  {
    accountStatementLineUid: string;
    data: AccountStatementLineStatusUpdateRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStatementLineStatus>>,
    {
      accountStatementLineUid: string;
      data: AccountStatementLineStatusUpdateRequest;
    }
  > = (props) => {
    const { accountStatementLineUid, data } = props ?? {};

    return updateStatementLineStatus(accountStatementLineUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateStatementLineStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStatementLineStatus>>
>;
export type UpdateStatementLineStatusMutationBody =
  AccountStatementLineStatusUpdateRequest;
export type UpdateStatementLineStatusMutationError = unknown;

export const useUpdateStatementLineStatus = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStatementLineStatus>>,
    TError,
    {
      accountStatementLineUid: string;
      data: AccountStatementLineStatusUpdateRequest;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateStatementLineStatus>>,
  TError,
  {
    accountStatementLineUid: string;
    data: AccountStatementLineStatusUpdateRequest;
  },
  TContext
> => {
  const mutationOptions = getUpdateStatementLineStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
