/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type DistributionType =
  typeof DistributionType[keyof typeof DistributionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DistributionType = {
  INCOME: 'INCOME',
  MATURITY: 'MATURITY',
  BONDSMITH_FEE_WITHDRAWAL: 'BONDSMITH_FEE_WITHDRAWAL',
  PLATFORM_FEE_WITHDRAWAL: 'PLATFORM_FEE_WITHDRAWAL',
} as const;
