/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  AvailablePlatformWithdrawalsParams,
  BondsmithPagePlatformFeeDisplayResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const platformWithdrawAllProductFees = (platformUid: string) => {
  return customInstance<void>({
    url: `/operations/platform-fee/platform/platform-withdrawal/${platformUid}`,
    method: 'POST',
  });
};

export const getPlatformWithdrawAllProductFeesMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformWithdrawAllProductFees>>,
    TError,
    { platformUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof platformWithdrawAllProductFees>>,
  TError,
  { platformUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformWithdrawAllProductFees>>,
    { platformUid: string }
  > = (props) => {
    const { platformUid } = props ?? {};

    return platformWithdrawAllProductFees(platformUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type PlatformWithdrawAllProductFeesMutationResult = NonNullable<
  Awaited<ReturnType<typeof platformWithdrawAllProductFees>>
>;

export type PlatformWithdrawAllProductFeesMutationError = unknown;

export const usePlatformWithdrawAllProductFees = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformWithdrawAllProductFees>>,
    TError,
    { platformUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof platformWithdrawAllProductFees>>,
  TError,
  { platformUid: string },
  TContext
> => {
  const mutationOptions =
    getPlatformWithdrawAllProductFeesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const platformWithdrawAllProductFees1 = (
  platformUid: string,
  amount: number
) => {
  return customInstance<void>({
    url: `/operations/platform-fee/platform/platform-withdrawal/${platformUid}/amount/${amount}`,
    method: 'POST',
  });
};

export const getPlatformWithdrawAllProductFees1MutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformWithdrawAllProductFees1>>,
    TError,
    { platformUid: string; amount: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof platformWithdrawAllProductFees1>>,
  TError,
  { platformUid: string; amount: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof platformWithdrawAllProductFees1>>,
    { platformUid: string; amount: number }
  > = (props) => {
    const { platformUid, amount } = props ?? {};

    return platformWithdrawAllProductFees1(platformUid, amount);
  };

  return { mutationFn, ...mutationOptions };
};

export type PlatformWithdrawAllProductFees1MutationResult = NonNullable<
  Awaited<ReturnType<typeof platformWithdrawAllProductFees1>>
>;

export type PlatformWithdrawAllProductFees1MutationError = unknown;

export const usePlatformWithdrawAllProductFees1 = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof platformWithdrawAllProductFees1>>,
    TError,
    { platformUid: string; amount: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof platformWithdrawAllProductFees1>>,
  TError,
  { platformUid: string; amount: number },
  TContext
> => {
  const mutationOptions =
    getPlatformWithdrawAllProductFees1MutationOptions(options);

  return useMutation(mutationOptions);
};
export const availablePlatformWithdrawals = (
  params?: AvailablePlatformWithdrawalsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePlatformFeeDisplayResponse>({
    url: `/operations/platform-fee/platform/withdrawal/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getAvailablePlatformWithdrawalsQueryKey = (
  params?: AvailablePlatformWithdrawalsParams
) => {
  return [
    `/operations/platform-fee/platform/withdrawal/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getAvailablePlatformWithdrawalsQueryOptions = <
  TData = Awaited<ReturnType<typeof availablePlatformWithdrawals>>,
  TError = unknown
>(
  params?: AvailablePlatformWithdrawalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof availablePlatformWithdrawals>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAvailablePlatformWithdrawalsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof availablePlatformWithdrawals>>
  > = ({ signal }) => availablePlatformWithdrawals(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof availablePlatformWithdrawals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AvailablePlatformWithdrawalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof availablePlatformWithdrawals>>
>;
export type AvailablePlatformWithdrawalsQueryError = unknown;

export const useAvailablePlatformWithdrawals = <
  TData = Awaited<ReturnType<typeof availablePlatformWithdrawals>>,
  TError = unknown
>(
  params?: AvailablePlatformWithdrawalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof availablePlatformWithdrawals>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAvailablePlatformWithdrawalsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const markFeesWithdrawnForProductAccount = (
  productAccountUid: string
) => {
  return customInstance<void>({
    url: `/operations/platform-fee/platform/withdrawal/${productAccountUid}`,
    method: 'POST',
  });
};

export const getMarkFeesWithdrawnForProductAccountMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markFeesWithdrawnForProductAccount>>,
    TError,
    { productAccountUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof markFeesWithdrawnForProductAccount>>,
  TError,
  { productAccountUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof markFeesWithdrawnForProductAccount>>,
    { productAccountUid: string }
  > = (props) => {
    const { productAccountUid } = props ?? {};

    return markFeesWithdrawnForProductAccount(productAccountUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type MarkFeesWithdrawnForProductAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof markFeesWithdrawnForProductAccount>>
>;

export type MarkFeesWithdrawnForProductAccountMutationError = unknown;

export const useMarkFeesWithdrawnForProductAccount = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof markFeesWithdrawnForProductAccount>>,
    TError,
    { productAccountUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof markFeesWithdrawnForProductAccount>>,
  TError,
  { productAccountUid: string },
  TContext
> => {
  const mutationOptions =
    getMarkFeesWithdrawnForProductAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
