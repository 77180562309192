/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type DepositProtectionScheme =
  typeof DepositProtectionScheme[keyof typeof DepositProtectionScheme];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepositProtectionScheme = {
  FSCS: 'FSCS',
  FDIC: 'FDIC',
  JBDCS: 'JBDCS',
} as const;
