/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  FeesAndInterestResponse,
  ListCustomerInterestAndFeesParams,
  ListCustomerProductAccountsTableViewParams,
  ListRealisedCustomerInterestAndFeesParams,
  ProductAccountResponse,
  RealisedFeesAndInterestResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listCustomerInterestAndFees = (
  params: ListCustomerInterestAndFeesParams,
  signal?: AbortSignal
) => {
  return customInstance<FeesAndInterestResponse>({
    url: `/operations/customer-product-account/interest/interest-and-fees/`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerInterestAndFeesQueryKey = (
  params: ListCustomerInterestAndFeesParams
) => {
  return [
    `/operations/customer-product-account/interest/interest-and-fees/`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerInterestAndFeesQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerInterestAndFees>>,
  TError = unknown
>(
  params: ListCustomerInterestAndFeesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerInterestAndFees>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomerInterestAndFeesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerInterestAndFees>>
  > = ({ signal }) => listCustomerInterestAndFees(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerInterestAndFees>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerInterestAndFeesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerInterestAndFees>>
>;
export type ListCustomerInterestAndFeesQueryError = unknown;

export const useListCustomerInterestAndFees = <
  TData = Awaited<ReturnType<typeof listCustomerInterestAndFees>>,
  TError = unknown
>(
  params: ListCustomerInterestAndFeesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerInterestAndFees>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerInterestAndFeesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listRealisedCustomerInterestAndFees = (
  params: ListRealisedCustomerInterestAndFeesParams,
  signal?: AbortSignal
) => {
  return customInstance<RealisedFeesAndInterestResponse>({
    url: `/operations/customer-product-account/interest/realised-interest-and-fees`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListRealisedCustomerInterestAndFeesQueryKey = (
  params: ListRealisedCustomerInterestAndFeesParams
) => {
  return [
    `/operations/customer-product-account/interest/realised-interest-and-fees`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListRealisedCustomerInterestAndFeesQueryOptions = <
  TData = Awaited<ReturnType<typeof listRealisedCustomerInterestAndFees>>,
  TError = unknown
>(
  params: ListRealisedCustomerInterestAndFeesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listRealisedCustomerInterestAndFees>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListRealisedCustomerInterestAndFeesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listRealisedCustomerInterestAndFees>>
  > = ({ signal }) => listRealisedCustomerInterestAndFees(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listRealisedCustomerInterestAndFees>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListRealisedCustomerInterestAndFeesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listRealisedCustomerInterestAndFees>>
>;
export type ListRealisedCustomerInterestAndFeesQueryError = unknown;

export const useListRealisedCustomerInterestAndFees = <
  TData = Awaited<ReturnType<typeof listRealisedCustomerInterestAndFees>>,
  TError = unknown
>(
  params: ListRealisedCustomerInterestAndFeesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listRealisedCustomerInterestAndFees>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListRealisedCustomerInterestAndFeesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCustomerProductAccountsTableView = (
  params?: ListCustomerProductAccountsTableViewParams,
  signal?: AbortSignal
) => {
  return customInstance<ProductAccountResponse[]>({
    url: `/operations/customer-product-account/interest/table-view`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerProductAccountsTableViewQueryKey = (
  params?: ListCustomerProductAccountsTableViewParams
) => {
  return [
    `/operations/customer-product-account/interest/table-view`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerProductAccountsTableViewQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerProductAccountsTableView>>,
  TError = unknown
>(
  params?: ListCustomerProductAccountsTableViewParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductAccountsTableView>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListCustomerProductAccountsTableViewQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerProductAccountsTableView>>
  > = ({ signal }) => listCustomerProductAccountsTableView(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerProductAccountsTableView>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerProductAccountsTableViewQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerProductAccountsTableView>>
>;
export type ListCustomerProductAccountsTableViewQueryError = unknown;

export const useListCustomerProductAccountsTableView = <
  TData = Awaited<ReturnType<typeof listCustomerProductAccountsTableView>>,
  TError = unknown
>(
  params?: ListCustomerProductAccountsTableViewParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductAccountsTableView>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerProductAccountsTableViewQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
