/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation } from 'react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const createFscsRecordsFile = (bankUid: string) => {
  return customInstance<void>({
    url: `/operations/bank/${bankUid}/fscsFile`,
    method: 'POST',
  });
};

export const getCreateFscsRecordsFileMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFscsRecordsFile>>,
    TError,
    { bankUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFscsRecordsFile>>,
  TError,
  { bankUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFscsRecordsFile>>,
    { bankUid: string }
  > = (props) => {
    const { bankUid } = props ?? {};

    return createFscsRecordsFile(bankUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateFscsRecordsFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof createFscsRecordsFile>>
>;

export type CreateFscsRecordsFileMutationError = unknown;

export const useCreateFscsRecordsFile = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFscsRecordsFile>>,
    TError,
    { bankUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createFscsRecordsFile>>,
  TError,
  { bankUid: string },
  TContext
> => {
  const mutationOptions = getCreateFscsRecordsFileMutationOptions(options);

  return useMutation(mutationOptions);
};
