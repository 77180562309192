/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  FetchWebhookEventsParams,
  PlatformWebhookUrlRequest,
  WebhookEventResponse,
  WebhookSubscriptionResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const fetchWebhookEvents = (
  params?: FetchWebhookEventsParams,
  signal?: AbortSignal
) => {
  return customInstance<WebhookEventResponse[]>({
    url: `/webhook/events`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchWebhookEventsQueryKey = (
  params?: FetchWebhookEventsParams
) => {
  return [`/webhook/events`, ...(params ? [params] : [])] as const;
};

export const getFetchWebhookEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchWebhookEvents>>,
  TError = unknown
>(
  params?: FetchWebhookEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchWebhookEvents>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchWebhookEventsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchWebhookEvents>>> =
    ({ signal }) => fetchWebhookEvents(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchWebhookEvents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchWebhookEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchWebhookEvents>>
>;
export type FetchWebhookEventsQueryError = unknown;

export const useFetchWebhookEvents = <
  TData = Awaited<ReturnType<typeof fetchWebhookEvents>>,
  TError = unknown
>(
  params?: FetchWebhookEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchWebhookEvents>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchWebhookEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createPlatformWebhook = (
  platformWebhookUrlRequest: PlatformWebhookUrlRequest
) => {
  return customInstance<void>({
    url: `/webhook/link`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: platformWebhookUrlRequest,
  });
};

export const getCreatePlatformWebhookMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlatformWebhook>>,
    TError,
    { data: PlatformWebhookUrlRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPlatformWebhook>>,
  TError,
  { data: PlatformWebhookUrlRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPlatformWebhook>>,
    { data: PlatformWebhookUrlRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createPlatformWebhook(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePlatformWebhookMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPlatformWebhook>>
>;
export type CreatePlatformWebhookMutationBody = PlatformWebhookUrlRequest;
export type CreatePlatformWebhookMutationError = unknown;

export const useCreatePlatformWebhook = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlatformWebhook>>,
    TError,
    { data: PlatformWebhookUrlRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPlatformWebhook>>,
  TError,
  { data: PlatformWebhookUrlRequest },
  TContext
> => {
  const mutationOptions = getCreatePlatformWebhookMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPlatformWebhooks = (
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<WebhookSubscriptionResponse>({
    url: `/webhook/${platformUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetPlatformWebhooksQueryKey = (platformUid: string) => {
  return [`/webhook/${platformUid}`] as const;
};

export const getGetPlatformWebhooksQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformWebhooks>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformWebhooks>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlatformWebhooksQueryKey(platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlatformWebhooks>>
  > = ({ signal }) => getPlatformWebhooks(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformWebhooks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlatformWebhooksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlatformWebhooks>>
>;
export type GetPlatformWebhooksQueryError = unknown;

export const useGetPlatformWebhooks = <
  TData = Awaited<ReturnType<typeof getPlatformWebhooks>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformWebhooks>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformWebhooksQueryOptions(platformUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
