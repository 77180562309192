/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageAccountTransactionPaginatedRecord,
  CustomerAccountDetailResponse,
  CustomerAccountResponse,
  CustomerProductAccountResponse,
  DepositBreakRequest,
  ListCustomerAccountsParams,
  ListCustomerProductAccountDetailsParams,
  ListCustomerProductAccountsParams,
  ListProductAccountTransactionsParams,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listCustomerAccounts = (
  params?: ListCustomerAccountsParams,
  signal?: AbortSignal
) => {
  return customInstance<CustomerAccountResponse[]>({
    url: `/operations/own/account`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerAccountsQueryKey = (
  params?: ListCustomerAccountsParams
) => {
  return [`/operations/own/account`, ...(params ? [params] : [])] as const;
};

export const getListCustomerAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerAccounts>>,
  TError = unknown
>(
  params?: ListCustomerAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerAccounts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomerAccountsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerAccounts>>
  > = ({ signal }) => listCustomerAccounts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerAccounts>>
>;
export type ListCustomerAccountsQueryError = unknown;

export const useListCustomerAccounts = <
  TData = Awaited<ReturnType<typeof listCustomerAccounts>>,
  TError = unknown
>(
  params?: ListCustomerAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerAccounts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerAccountsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const withdrawResidualBalance = () => {
  return customInstance<string[]>({
    url: `/operations/own/account/balance/residual/withdraw`,
    method: 'POST',
  });
};

export const getWithdrawResidualBalanceMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawResidualBalance>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof withdrawResidualBalance>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof withdrawResidualBalance>>,
    void
  > = () => {
    return withdrawResidualBalance();
  };

  return { mutationFn, ...mutationOptions };
};

export type WithdrawResidualBalanceMutationResult = NonNullable<
  Awaited<ReturnType<typeof withdrawResidualBalance>>
>;

export type WithdrawResidualBalanceMutationError = unknown;

export const useWithdrawResidualBalance = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawResidualBalance>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof withdrawResidualBalance>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getWithdrawResidualBalanceMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listCustomerProductAccountDetails = (
  params: ListCustomerProductAccountDetailsParams,
  signal?: AbortSignal
) => {
  return customInstance<CustomerAccountDetailResponse[]>({
    url: `/operations/own/account/product/accounts`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerProductAccountDetailsQueryKey = (
  params: ListCustomerProductAccountDetailsParams
) => {
  return [
    `/operations/own/account/product/accounts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerProductAccountDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerProductAccountDetails>>,
  TError = unknown
>(
  params: ListCustomerProductAccountDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductAccountDetails>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListCustomerProductAccountDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerProductAccountDetails>>
  > = ({ signal }) => listCustomerProductAccountDetails(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerProductAccountDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerProductAccountDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerProductAccountDetails>>
>;
export type ListCustomerProductAccountDetailsQueryError = unknown;

export const useListCustomerProductAccountDetails = <
  TData = Awaited<ReturnType<typeof listCustomerProductAccountDetails>>,
  TError = unknown
>(
  params: ListCustomerProductAccountDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductAccountDetails>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerProductAccountDetailsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCustomerProductAccounts = (
  params?: ListCustomerProductAccountsParams,
  signal?: AbortSignal
) => {
  return customInstance<CustomerProductAccountResponse[]>({
    url: `/operations/own/account/products`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerProductAccountsQueryKey = (
  params?: ListCustomerProductAccountsParams
) => {
  return [
    `/operations/own/account/products`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerProductAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerProductAccounts>>,
  TError = unknown
>(
  params?: ListCustomerProductAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductAccounts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomerProductAccountsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerProductAccounts>>
  > = ({ signal }) => listCustomerProductAccounts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerProductAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerProductAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerProductAccounts>>
>;
export type ListCustomerProductAccountsQueryError = unknown;

export const useListCustomerProductAccounts = <
  TData = Awaited<ReturnType<typeof listCustomerProductAccounts>>,
  TError = unknown
>(
  params?: ListCustomerProductAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductAccounts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerProductAccountsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listProductAccountTransactions = (
  params?: ListProductAccountTransactionsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageAccountTransactionPaginatedRecord>({
    url: `/operations/own/account/transactions/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListProductAccountTransactionsQueryKey = (
  params?: ListProductAccountTransactionsParams
) => {
  return [
    `/operations/own/account/transactions/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListProductAccountTransactionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listProductAccountTransactions>>,
  TError = unknown
>(
  params?: ListProductAccountTransactionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProductAccountTransactions>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListProductAccountTransactionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listProductAccountTransactions>>
  > = ({ signal }) => listProductAccountTransactions(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listProductAccountTransactions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListProductAccountTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProductAccountTransactions>>
>;
export type ListProductAccountTransactionsQueryError = unknown;

export const useListProductAccountTransactions = <
  TData = Awaited<ReturnType<typeof listProductAccountTransactions>>,
  TError = unknown
>(
  params?: ListProductAccountTransactionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProductAccountTransactions>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListProductAccountTransactionsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const requestTermDepositMaturityBreak = (
  productAccountUid: string,
  depositBreakRequest: DepositBreakRequest
) => {
  return customInstance<void>({
    url: `/operations/own/account/${productAccountUid}/term/maturity/break`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: depositBreakRequest,
  });
};

export const getRequestTermDepositMaturityBreakMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestTermDepositMaturityBreak>>,
    TError,
    { productAccountUid: string; data: DepositBreakRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof requestTermDepositMaturityBreak>>,
  TError,
  { productAccountUid: string; data: DepositBreakRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestTermDepositMaturityBreak>>,
    { productAccountUid: string; data: DepositBreakRequest }
  > = (props) => {
    const { productAccountUid, data } = props ?? {};

    return requestTermDepositMaturityBreak(productAccountUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestTermDepositMaturityBreakMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestTermDepositMaturityBreak>>
>;
export type RequestTermDepositMaturityBreakMutationBody = DepositBreakRequest;
export type RequestTermDepositMaturityBreakMutationError = unknown;

export const useRequestTermDepositMaturityBreak = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestTermDepositMaturityBreak>>,
    TError,
    { productAccountUid: string; data: DepositBreakRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof requestTermDepositMaturityBreak>>,
  TError,
  { productAccountUid: string; data: DepositBreakRequest },
  TContext
> => {
  const mutationOptions =
    getRequestTermDepositMaturityBreakMutationOptions(options);

  return useMutation(mutationOptions);
};
