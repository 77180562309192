/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InstructionType =
  typeof InstructionType[keyof typeof InstructionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InstructionType = {
  DEPOSIT: 'DEPOSIT',
  PARTIAL_WITHDRAWAL: 'PARTIAL_WITHDRAWAL',
  FULL_WITHDRAWAL: 'FULL_WITHDRAWAL',
} as const;
