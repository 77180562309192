/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankCreditScoreResponse,
  CreateBankCreditScoreRequest,
  UpdateBankCreditScoreRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const createBankCreditScore = (
  createBankCreditScoreRequest: CreateBankCreditScoreRequest
) => {
  return customInstance<string>({
    url: `/operations/banks/credit-score`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createBankCreditScoreRequest,
  });
};

export const getCreateBankCreditScoreMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBankCreditScore>>,
    TError,
    { data: CreateBankCreditScoreRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createBankCreditScore>>,
  TError,
  { data: CreateBankCreditScoreRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createBankCreditScore>>,
    { data: CreateBankCreditScoreRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createBankCreditScore(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateBankCreditScoreMutationResult = NonNullable<
  Awaited<ReturnType<typeof createBankCreditScore>>
>;
export type CreateBankCreditScoreMutationBody = CreateBankCreditScoreRequest;
export type CreateBankCreditScoreMutationError = unknown;

export const useCreateBankCreditScore = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBankCreditScore>>,
    TError,
    { data: CreateBankCreditScoreRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createBankCreditScore>>,
  TError,
  { data: CreateBankCreditScoreRequest },
  TContext
> => {
  const mutationOptions = getCreateBankCreditScoreMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getBankCreditScore = (bankUid: string, signal?: AbortSignal) => {
  return customInstance<BankCreditScoreResponse>({
    url: `/operations/banks/credit-score/${bankUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetBankCreditScoreQueryKey = (bankUid: string) => {
  return [`/operations/banks/credit-score/${bankUid}`] as const;
};

export const getGetBankCreditScoreQueryOptions = <
  TData = Awaited<ReturnType<typeof getBankCreditScore>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBankCreditScore>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBankCreditScoreQueryKey(bankUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankCreditScore>>> =
    ({ signal }) => getBankCreditScore(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBankCreditScore>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBankCreditScoreQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBankCreditScore>>
>;
export type GetBankCreditScoreQueryError = unknown;

export const useGetBankCreditScore = <
  TData = Awaited<ReturnType<typeof getBankCreditScore>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBankCreditScore>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBankCreditScoreQueryOptions(bankUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateBankCreditScore = (
  bankUid: string,
  updateBankCreditScoreRequest: UpdateBankCreditScoreRequest
) => {
  return customInstance<string>({
    url: `/operations/banks/credit-score/${bankUid}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateBankCreditScoreRequest,
  });
};

export const getUpdateBankCreditScoreMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBankCreditScore>>,
    TError,
    { bankUid: string; data: UpdateBankCreditScoreRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateBankCreditScore>>,
  TError,
  { bankUid: string; data: UpdateBankCreditScoreRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBankCreditScore>>,
    { bankUid: string; data: UpdateBankCreditScoreRequest }
  > = (props) => {
    const { bankUid, data } = props ?? {};

    return updateBankCreditScore(bankUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBankCreditScoreMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateBankCreditScore>>
>;
export type UpdateBankCreditScoreMutationBody = UpdateBankCreditScoreRequest;
export type UpdateBankCreditScoreMutationError = unknown;

export const useUpdateBankCreditScore = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBankCreditScore>>,
    TError,
    { bankUid: string; data: UpdateBankCreditScoreRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateBankCreditScore>>,
  TError,
  { bankUid: string; data: UpdateBankCreditScoreRequest },
  TContext
> => {
  const mutationOptions = getUpdateBankCreditScoreMutationOptions(options);

  return useMutation(mutationOptions);
};
