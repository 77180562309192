/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type FilePartnerPlatformIntegrationType =
  typeof FilePartnerPlatformIntegrationType[keyof typeof FilePartnerPlatformIntegrationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilePartnerPlatformIntegrationType = {
  BONDSMITH: 'BONDSMITH',
  FNZ: 'FNZ',
  DEFAULT: 'DEFAULT',
} as const;
