/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type RegulatorType = typeof RegulatorType[keyof typeof RegulatorType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegulatorType = {
  FCA: 'FCA',
  PRA: 'PRA',
  JFSC: 'JFSC',
} as const;
