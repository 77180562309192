/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankProductClosureRequest,
  BankProductRequestWithRateAndAccountDetails,
  BankProductUpdateWithAccountDetailRequest,
  ListBankProposalsParams,
  ListOutstandingBankProposalsParams,
  ListProductProposalsParams,
  ListableProductProposalResponse,
  PartnerBankCreateRequest,
  PartnerBankProposalResponse,
  PartnerBankUpdateRequest,
  ProductProposalResponse,
  ProposalApprovalResponse,
  RateDetailUpdateRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listBankProposals = (
  params?: ListBankProposalsParams,
  signal?: AbortSignal
) => {
  return customInstance<PartnerBankProposalResponse[]>({
    url: `/operations/proposals/bank-proposals`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListBankProposalsQueryKey = (
  params?: ListBankProposalsParams
) => {
  return [
    `/operations/proposals/bank-proposals`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListBankProposalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listBankProposals>>,
  TError = unknown
>(
  params?: ListBankProposalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listBankProposals>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListBankProposalsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBankProposals>>> =
    ({ signal }) => listBankProposals(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listBankProposals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBankProposalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBankProposals>>
>;
export type ListBankProposalsQueryError = unknown;

export const useListBankProposals = <
  TData = Awaited<ReturnType<typeof listBankProposals>>,
  TError = unknown
>(
  params?: ListBankProposalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listBankProposals>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBankProposalsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listOutstandingBankProposals = (
  params?: ListOutstandingBankProposalsParams,
  signal?: AbortSignal
) => {
  return customInstance<PartnerBankProposalResponse[]>({
    url: `/operations/proposals/bank-proposals/outstanding`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListOutstandingBankProposalsQueryKey = (
  params?: ListOutstandingBankProposalsParams
) => {
  return [
    `/operations/proposals/bank-proposals/outstanding`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListOutstandingBankProposalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listOutstandingBankProposals>>,
  TError = unknown
>(
  params?: ListOutstandingBankProposalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOutstandingBankProposals>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListOutstandingBankProposalsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listOutstandingBankProposals>>
  > = ({ signal }) => listOutstandingBankProposals(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listOutstandingBankProposals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOutstandingBankProposalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listOutstandingBankProposals>>
>;
export type ListOutstandingBankProposalsQueryError = unknown;

export const useListOutstandingBankProposals = <
  TData = Awaited<ReturnType<typeof listOutstandingBankProposals>>,
  TError = unknown
>(
  params?: ListOutstandingBankProposalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOutstandingBankProposals>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOutstandingBankProposalsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchBankProposal1 = (
  proposalUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PartnerBankProposalResponse>({
    url: `/operations/proposals/bank-proposals/${proposalUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchBankProposal1QueryKey = (proposalUid: string) => {
  return [`/operations/proposals/bank-proposals/${proposalUid}`] as const;
};

export const getFetchBankProposal1QueryOptions = <
  TData = Awaited<ReturnType<typeof fetchBankProposal1>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBankProposal1>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchBankProposal1QueryKey(proposalUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchBankProposal1>>> =
    ({ signal }) => fetchBankProposal1(proposalUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proposalUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchBankProposal1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchBankProposal1QueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchBankProposal1>>
>;
export type FetchBankProposal1QueryError = unknown;

export const useFetchBankProposal1 = <
  TData = Awaited<ReturnType<typeof fetchBankProposal1>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBankProposal1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchBankProposal1QueryOptions(proposalUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createBank = (
  partnerBankCreateRequest: PartnerBankCreateRequest
) => {
  return customInstance<string>({
    url: `/operations/proposals/banks`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: partnerBankCreateRequest,
  });
};

export const getCreateBankMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBank>>,
    TError,
    { data: PartnerBankCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createBank>>,
  TError,
  { data: PartnerBankCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createBank>>,
    { data: PartnerBankCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createBank(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateBankMutationResult = NonNullable<
  Awaited<ReturnType<typeof createBank>>
>;
export type CreateBankMutationBody = PartnerBankCreateRequest;
export type CreateBankMutationError = unknown;

export const useCreateBank = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBank>>,
    TError,
    { data: PartnerBankCreateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createBank>>,
  TError,
  { data: PartnerBankCreateRequest },
  TContext
> => {
  const mutationOptions = getCreateBankMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeBank = (
  partnerBankCreateRequest: PartnerBankCreateRequest
) => {
  return customInstance<string>({
    url: `/operations/proposals/banks/propose`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: partnerBankCreateRequest,
  });
};

export const getProposeBankMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeBank>>,
    TError,
    { data: PartnerBankCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeBank>>,
  TError,
  { data: PartnerBankCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeBank>>,
    { data: PartnerBankCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return proposeBank(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeBankMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeBank>>
>;
export type ProposeBankMutationBody = PartnerBankCreateRequest;
export type ProposeBankMutationError = unknown;

export const useProposeBank = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeBank>>,
    TError,
    { data: PartnerBankCreateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeBank>>,
  TError,
  { data: PartnerBankCreateRequest },
  TContext
> => {
  const mutationOptions = getProposeBankMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateBank = (
  bankUid: string,
  partnerBankUpdateRequest: PartnerBankUpdateRequest
) => {
  return customInstance<string>({
    url: `/operations/proposals/banks/${bankUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: partnerBankUpdateRequest,
  });
};

export const getUpdateBankMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBank>>,
    TError,
    { bankUid: string; data: PartnerBankUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateBank>>,
  TError,
  { bankUid: string; data: PartnerBankUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBank>>,
    { bankUid: string; data: PartnerBankUpdateRequest }
  > = (props) => {
    const { bankUid, data } = props ?? {};

    return updateBank(bankUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBankMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateBank>>
>;
export type UpdateBankMutationBody = PartnerBankUpdateRequest;
export type UpdateBankMutationError = unknown;

export const useUpdateBank = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBank>>,
    TError,
    { bankUid: string; data: PartnerBankUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateBank>>,
  TError,
  { bankUid: string; data: PartnerBankUpdateRequest },
  TContext
> => {
  const mutationOptions = getUpdateBankMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeProduct = (
  bankUid: string,
  bankProductRequestWithRateAndAccountDetails: BankProductRequestWithRateAndAccountDetails
) => {
  return customInstance<ProposalApprovalResponse>({
    url: `/operations/proposals/banks/${bankUid}/products`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bankProductRequestWithRateAndAccountDetails,
  });
};

export const getProposeProductMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeProduct>>,
    TError,
    { bankUid: string; data: BankProductRequestWithRateAndAccountDetails },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeProduct>>,
  TError,
  { bankUid: string; data: BankProductRequestWithRateAndAccountDetails },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeProduct>>,
    { bankUid: string; data: BankProductRequestWithRateAndAccountDetails }
  > = (props) => {
    const { bankUid, data } = props ?? {};

    return proposeProduct(bankUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeProduct>>
>;
export type ProposeProductMutationBody =
  BankProductRequestWithRateAndAccountDetails;
export type ProposeProductMutationError = unknown;

export const useProposeProduct = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeProduct>>,
    TError,
    { bankUid: string; data: BankProductRequestWithRateAndAccountDetails },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeProduct>>,
  TError,
  { bankUid: string; data: BankProductRequestWithRateAndAccountDetails },
  TContext
> => {
  const mutationOptions = getProposeProductMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeUpdateProduct = (
  bankUid: string,
  productUid: string,
  bankProductUpdateWithAccountDetailRequest: BankProductUpdateWithAccountDetailRequest
) => {
  return customInstance<void>({
    url: `/operations/proposals/banks/${bankUid}/products/${productUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: bankProductUpdateWithAccountDetailRequest,
  });
};

export const getProposeUpdateProductMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeUpdateProduct>>,
    TError,
    {
      bankUid: string;
      productUid: string;
      data: BankProductUpdateWithAccountDetailRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeUpdateProduct>>,
  TError,
  {
    bankUid: string;
    productUid: string;
    data: BankProductUpdateWithAccountDetailRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeUpdateProduct>>,
    {
      bankUid: string;
      productUid: string;
      data: BankProductUpdateWithAccountDetailRequest;
    }
  > = (props) => {
    const { bankUid, productUid, data } = props ?? {};

    return proposeUpdateProduct(bankUid, productUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeUpdateProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeUpdateProduct>>
>;
export type ProposeUpdateProductMutationBody =
  BankProductUpdateWithAccountDetailRequest;
export type ProposeUpdateProductMutationError = unknown;

export const useProposeUpdateProduct = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeUpdateProduct>>,
    TError,
    {
      bankUid: string;
      productUid: string;
      data: BankProductUpdateWithAccountDetailRequest;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeUpdateProduct>>,
  TError,
  {
    bankUid: string;
    productUid: string;
    data: BankProductUpdateWithAccountDetailRequest;
  },
  TContext
> => {
  const mutationOptions = getProposeUpdateProductMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeRetireProduct = (
  bankUid: string,
  productUid: string,
  bankProductClosureRequest: BankProductClosureRequest
) => {
  return customInstance<void>({
    url: `/operations/proposals/banks/${bankUid}/products/${productUid}`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: bankProductClosureRequest,
  });
};

export const getProposeRetireProductMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeRetireProduct>>,
    TError,
    { bankUid: string; productUid: string; data: BankProductClosureRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeRetireProduct>>,
  TError,
  { bankUid: string; productUid: string; data: BankProductClosureRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeRetireProduct>>,
    { bankUid: string; productUid: string; data: BankProductClosureRequest }
  > = (props) => {
    const { bankUid, productUid, data } = props ?? {};

    return proposeRetireProduct(bankUid, productUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeRetireProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeRetireProduct>>
>;
export type ProposeRetireProductMutationBody = BankProductClosureRequest;
export type ProposeRetireProductMutationError = unknown;

export const useProposeRetireProduct = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeRetireProduct>>,
    TError,
    { bankUid: string; productUid: string; data: BankProductClosureRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeRetireProduct>>,
  TError,
  { bankUid: string; productUid: string; data: BankProductClosureRequest },
  TContext
> => {
  const mutationOptions = getProposeRetireProductMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeProductSoftClosure = (
  bankUid: string,
  productUid: string,
  bankProductClosureRequest: BankProductClosureRequest
) => {
  return customInstance<void>({
    url: `/operations/proposals/banks/${bankUid}/products/${productUid}/close/soft`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bankProductClosureRequest,
  });
};

export const getProposeProductSoftClosureMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeProductSoftClosure>>,
    TError,
    { bankUid: string; productUid: string; data: BankProductClosureRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeProductSoftClosure>>,
  TError,
  { bankUid: string; productUid: string; data: BankProductClosureRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeProductSoftClosure>>,
    { bankUid: string; productUid: string; data: BankProductClosureRequest }
  > = (props) => {
    const { bankUid, productUid, data } = props ?? {};

    return proposeProductSoftClosure(bankUid, productUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeProductSoftClosureMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeProductSoftClosure>>
>;
export type ProposeProductSoftClosureMutationBody = BankProductClosureRequest;
export type ProposeProductSoftClosureMutationError = unknown;

export const useProposeProductSoftClosure = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeProductSoftClosure>>,
    TError,
    { bankUid: string; productUid: string; data: BankProductClosureRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeProductSoftClosure>>,
  TError,
  { bankUid: string; productUid: string; data: BankProductClosureRequest },
  TContext
> => {
  const mutationOptions = getProposeProductSoftClosureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeUpdateRate = (
  bankUid: string,
  productUid: string,
  rateDetailUpdateRequest: RateDetailUpdateRequest
) => {
  return customInstance<void>({
    url: `/operations/proposals/banks/${bankUid}/products/${productUid}/rates`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: rateDetailUpdateRequest,
  });
};

export const getProposeUpdateRateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeUpdateRate>>,
    TError,
    { bankUid: string; productUid: string; data: RateDetailUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeUpdateRate>>,
  TError,
  { bankUid: string; productUid: string; data: RateDetailUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeUpdateRate>>,
    { bankUid: string; productUid: string; data: RateDetailUpdateRequest }
  > = (props) => {
    const { bankUid, productUid, data } = props ?? {};

    return proposeUpdateRate(bankUid, productUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeUpdateRateMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeUpdateRate>>
>;
export type ProposeUpdateRateMutationBody = RateDetailUpdateRequest;
export type ProposeUpdateRateMutationError = unknown;

export const useProposeUpdateRate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeUpdateRate>>,
    TError,
    { bankUid: string; productUid: string; data: RateDetailUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeUpdateRate>>,
  TError,
  { bankUid: string; productUid: string; data: RateDetailUpdateRequest },
  TContext
> => {
  const mutationOptions = getProposeUpdateRateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeBankUpdate = (
  bankUid: string,
  partnerBankUpdateRequest: PartnerBankUpdateRequest
) => {
  return customInstance<string>({
    url: `/operations/proposals/banks/${bankUid}/propose`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: partnerBankUpdateRequest,
  });
};

export const getProposeBankUpdateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeBankUpdate>>,
    TError,
    { bankUid: string; data: PartnerBankUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeBankUpdate>>,
  TError,
  { bankUid: string; data: PartnerBankUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeBankUpdate>>,
    { bankUid: string; data: PartnerBankUpdateRequest }
  > = (props) => {
    const { bankUid, data } = props ?? {};

    return proposeBankUpdate(bankUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeBankUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeBankUpdate>>
>;
export type ProposeBankUpdateMutationBody = PartnerBankUpdateRequest;
export type ProposeBankUpdateMutationError = unknown;

export const useProposeBankUpdate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeBankUpdate>>,
    TError,
    { bankUid: string; data: PartnerBankUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeBankUpdate>>,
  TError,
  { bankUid: string; data: PartnerBankUpdateRequest },
  TContext
> => {
  const mutationOptions = getProposeBankUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeBankRetire = (bankUid: string) => {
  return customInstance<string>({
    url: `/operations/proposals/banks/${bankUid}/propose`,
    method: 'DELETE',
  });
};

export const getProposeBankRetireMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeBankRetire>>,
    TError,
    { bankUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeBankRetire>>,
  TError,
  { bankUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeBankRetire>>,
    { bankUid: string }
  > = (props) => {
    const { bankUid } = props ?? {};

    return proposeBankRetire(bankUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeBankRetireMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeBankRetire>>
>;

export type ProposeBankRetireMutationError = unknown;

export const useProposeBankRetire = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeBankRetire>>,
    TError,
    { bankUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeBankRetire>>,
  TError,
  { bankUid: string },
  TContext
> => {
  const mutationOptions = getProposeBankRetireMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listProductProposals = (
  params?: ListProductProposalsParams,
  signal?: AbortSignal
) => {
  return customInstance<ListableProductProposalResponse[]>({
    url: `/operations/proposals/product-proposals`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListProductProposalsQueryKey = (
  params?: ListProductProposalsParams
) => {
  return [
    `/operations/proposals/product-proposals`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListProductProposalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listProductProposals>>,
  TError = unknown
>(
  params?: ListProductProposalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProductProposals>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListProductProposalsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listProductProposals>>
  > = ({ signal }) => listProductProposals(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listProductProposals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListProductProposalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProductProposals>>
>;
export type ListProductProposalsQueryError = unknown;

export const useListProductProposals = <
  TData = Awaited<ReturnType<typeof listProductProposals>>,
  TError = unknown
>(
  params?: ListProductProposalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProductProposals>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListProductProposalsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchProposal = (proposalUid: string, signal?: AbortSignal) => {
  return customInstance<ProductProposalResponse>({
    url: `/operations/proposals/product-proposals/${proposalUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchProposalQueryKey = (proposalUid: string) => {
  return [`/operations/proposals/product-proposals/${proposalUid}`] as const;
};

export const getFetchProposalQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProposal>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchProposalQueryKey(proposalUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchProposal>>> = ({
    signal,
  }) => fetchProposal(proposalUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proposalUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProposal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProposalQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProposal>>
>;
export type FetchProposalQueryError = unknown;

export const useFetchProposal = <
  TData = Awaited<ReturnType<typeof fetchProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProposal>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProposalQueryOptions(proposalUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
