/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalAccountStatus =
  typeof InternalAccountStatus[keyof typeof InternalAccountStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalAccountStatus = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
} as const;
