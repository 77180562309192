/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation } from 'react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const generateBankInvoicingReport = (
  bankUid: string,
  fromDate: string,
  toDate: string
) => {
  return customInstance<void>({
    url: `/operations/report/bank/${bankUid}/from/${fromDate}/to/${toDate}`,
    method: 'POST',
  });
};

export const getGenerateBankInvoicingReportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateBankInvoicingReport>>,
    TError,
    { bankUid: string; fromDate: string; toDate: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateBankInvoicingReport>>,
  TError,
  { bankUid: string; fromDate: string; toDate: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateBankInvoicingReport>>,
    { bankUid: string; fromDate: string; toDate: string }
  > = (props) => {
    const { bankUid, fromDate, toDate } = props ?? {};

    return generateBankInvoicingReport(bankUid, fromDate, toDate);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateBankInvoicingReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateBankInvoicingReport>>
>;

export type GenerateBankInvoicingReportMutationError = unknown;

export const useGenerateBankInvoicingReport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateBankInvoicingReport>>,
    TError,
    { bankUid: string; fromDate: string; toDate: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateBankInvoicingReport>>,
  TError,
  { bankUid: string; fromDate: string; toDate: string },
  TContext
> => {
  const mutationOptions =
    getGenerateBankInvoicingReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const generateDistributionReport = (
  fromDate: string,
  toDate: string
) => {
  return customInstance<void>({
    url: `/operations/report/order/distribution/from/${fromDate}/to/${toDate}`,
    method: 'POST',
  });
};

export const getGenerateDistributionReportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateDistributionReport>>,
    TError,
    { fromDate: string; toDate: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateDistributionReport>>,
  TError,
  { fromDate: string; toDate: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateDistributionReport>>,
    { fromDate: string; toDate: string }
  > = (props) => {
    const { fromDate, toDate } = props ?? {};

    return generateDistributionReport(fromDate, toDate);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateDistributionReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateDistributionReport>>
>;

export type GenerateDistributionReportMutationError = unknown;

export const useGenerateDistributionReport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateDistributionReport>>,
    TError,
    { fromDate: string; toDate: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateDistributionReport>>,
  TError,
  { fromDate: string; toDate: string },
  TContext
> => {
  const mutationOptions = getGenerateDistributionReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const generatePlatformProductAccountReport = (
  platformUid: string,
  fromDate: string,
  toDate: string
) => {
  return customInstance<void>({
    url: `/operations/report/platform/product-account/${platformUid}/from/${fromDate}/to/${toDate}`,
    method: 'POST',
  });
};

export const getGeneratePlatformProductAccountReportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generatePlatformProductAccountReport>>,
    TError,
    { platformUid: string; fromDate: string; toDate: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generatePlatformProductAccountReport>>,
  TError,
  { platformUid: string; fromDate: string; toDate: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generatePlatformProductAccountReport>>,
    { platformUid: string; fromDate: string; toDate: string }
  > = (props) => {
    const { platformUid, fromDate, toDate } = props ?? {};

    return generatePlatformProductAccountReport(platformUid, fromDate, toDate);
  };

  return { mutationFn, ...mutationOptions };
};

export type GeneratePlatformProductAccountReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof generatePlatformProductAccountReport>>
>;

export type GeneratePlatformProductAccountReportMutationError = unknown;

export const useGeneratePlatformProductAccountReport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generatePlatformProductAccountReport>>,
    TError,
    { platformUid: string; fromDate: string; toDate: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generatePlatformProductAccountReport>>,
  TError,
  { platformUid: string; fromDate: string; toDate: string },
  TContext
> => {
  const mutationOptions =
    getGeneratePlatformProductAccountReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const generatePlatformInvoicingReport = (
  platformUid: string,
  fromDate: string,
  toDate: string
) => {
  return customInstance<void>({
    url: `/operations/report/platform/${platformUid}/from/${fromDate}/to/${toDate}`,
    method: 'POST',
  });
};

export const getGeneratePlatformInvoicingReportMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generatePlatformInvoicingReport>>,
    TError,
    { platformUid: string; fromDate: string; toDate: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generatePlatformInvoicingReport>>,
  TError,
  { platformUid: string; fromDate: string; toDate: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generatePlatformInvoicingReport>>,
    { platformUid: string; fromDate: string; toDate: string }
  > = (props) => {
    const { platformUid, fromDate, toDate } = props ?? {};

    return generatePlatformInvoicingReport(platformUid, fromDate, toDate);
  };

  return { mutationFn, ...mutationOptions };
};

export type GeneratePlatformInvoicingReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof generatePlatformInvoicingReport>>
>;

export type GeneratePlatformInvoicingReportMutationError = unknown;

export const useGeneratePlatformInvoicingReport = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generatePlatformInvoicingReport>>,
    TError,
    { platformUid: string; fromDate: string; toDate: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generatePlatformInvoicingReport>>,
  TError,
  { platformUid: string; fromDate: string; toDate: string },
  TContext
> => {
  const mutationOptions =
    getGeneratePlatformInvoicingReportMutationOptions(options);

  return useMutation(mutationOptions);
};
