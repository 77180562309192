/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type { PlatformProductBalancesResponse } from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const fetchProductBalancesForBank = (
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductBalancesResponse>({
    url: `/operations/own/balance/banks/${bankUid}/product`,
    method: 'GET',
    signal,
  });
};

export const getFetchProductBalancesForBankQueryKey = (bankUid: string) => {
  return [`/operations/own/balance/banks/${bankUid}/product`] as const;
};

export const getFetchProductBalancesForBankQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProductBalancesForBank>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductBalancesForBank>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchProductBalancesForBankQueryKey(bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchProductBalancesForBank>>
  > = ({ signal }) => fetchProductBalancesForBank(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProductBalancesForBank>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProductBalancesForBankQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProductBalancesForBank>>
>;
export type FetchProductBalancesForBankQueryError = unknown;

export const useFetchProductBalancesForBank = <
  TData = Awaited<ReturnType<typeof fetchProductBalancesForBank>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductBalancesForBank>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProductBalancesForBankQueryOptions(
    bankUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchProductBalancesForPlatform = (
  bankUid: string,
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductBalancesResponse>({
    url: `/operations/own/balance/banks/${bankUid}/product/platform/${platformUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchProductBalancesForPlatformQueryKey = (
  bankUid: string,
  platformUid: string
) => {
  return [
    `/operations/own/balance/banks/${bankUid}/product/platform/${platformUid}`,
  ] as const;
};

export const getFetchProductBalancesForPlatformQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProductBalancesForPlatform>>,
  TError = unknown
>(
  bankUid: string,
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductBalancesForPlatform>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchProductBalancesForPlatformQueryKey(bankUid, platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchProductBalancesForPlatform>>
  > = ({ signal }) =>
    fetchProductBalancesForPlatform(bankUid, platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(bankUid && platformUid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProductBalancesForPlatform>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProductBalancesForPlatformQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProductBalancesForPlatform>>
>;
export type FetchProductBalancesForPlatformQueryError = unknown;

export const useFetchProductBalancesForPlatform = <
  TData = Awaited<ReturnType<typeof fetchProductBalancesForPlatform>>,
  TError = unknown
>(
  bankUid: string,
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductBalancesForPlatform>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProductBalancesForPlatformQueryOptions(
    bankUid,
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchProductBalance = (
  bankUid: string,
  productUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductBalancesResponse>({
    url: `/operations/own/balance/banks/${bankUid}/product/${productUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchProductBalanceQueryKey = (
  bankUid: string,
  productUid: string
) => {
  return [
    `/operations/own/balance/banks/${bankUid}/product/${productUid}`,
  ] as const;
};

export const getFetchProductBalanceQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProductBalance>>,
  TError = unknown
>(
  bankUid: string,
  productUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductBalance>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchProductBalanceQueryKey(bankUid, productUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchProductBalance>>
  > = ({ signal }) => fetchProductBalance(bankUid, productUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(bankUid && productUid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProductBalance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProductBalanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProductBalance>>
>;
export type FetchProductBalanceQueryError = unknown;

export const useFetchProductBalance = <
  TData = Awaited<ReturnType<typeof fetchProductBalance>>,
  TError = unknown
>(
  bankUid: string,
  productUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductBalance>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProductBalanceQueryOptions(
    bankUid,
    productUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchProductBalanceForPlatform = (
  bankUid: string,
  productUid: string,
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductBalancesResponse>({
    url: `/operations/own/balance/banks/${bankUid}/product/${productUid}/platform/${platformUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchProductBalanceForPlatformQueryKey = (
  bankUid: string,
  productUid: string,
  platformUid: string
) => {
  return [
    `/operations/own/balance/banks/${bankUid}/product/${productUid}/platform/${platformUid}`,
  ] as const;
};

export const getFetchProductBalanceForPlatformQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProductBalanceForPlatform>>,
  TError = unknown
>(
  bankUid: string,
  productUid: string,
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductBalanceForPlatform>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchProductBalanceForPlatformQueryKey(bankUid, productUid, platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchProductBalanceForPlatform>>
  > = ({ signal }) =>
    fetchProductBalanceForPlatform(bankUid, productUid, platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(bankUid && productUid && platformUid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProductBalanceForPlatform>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProductBalanceForPlatformQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProductBalanceForPlatform>>
>;
export type FetchProductBalanceForPlatformQueryError = unknown;

export const useFetchProductBalanceForPlatform = <
  TData = Awaited<ReturnType<typeof fetchProductBalanceForPlatform>>,
  TError = unknown
>(
  bankUid: string,
  productUid: string,
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProductBalanceForPlatform>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProductBalanceForPlatformQueryOptions(
    bankUid,
    productUid,
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
