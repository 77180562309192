/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageUpcomingMaturitiesPaginatedRecord,
  IncomingInstructionsResponse,
  ListUpcomingMaturitiesParams,
  UpcomingNoticeWithdrawalsResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listProductAmountsExpectedToGoToBank = (
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<IncomingInstructionsResponse[]>({
    url: `/operations/own/insights/incoming-instructions/${bankUid}/expected`,
    method: 'GET',
    signal,
  });
};

export const getListProductAmountsExpectedToGoToBankQueryKey = (
  bankUid: string
) => {
  return [
    `/operations/own/insights/incoming-instructions/${bankUid}/expected`,
  ] as const;
};

export const getListProductAmountsExpectedToGoToBankQueryOptions = <
  TData = Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListProductAmountsExpectedToGoToBankQueryKey(bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>
  > = ({ signal }) => listProductAmountsExpectedToGoToBank(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListProductAmountsExpectedToGoToBankQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>
>;
export type ListProductAmountsExpectedToGoToBankQueryError = unknown;

export const useListProductAmountsExpectedToGoToBank = <
  TData = Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListProductAmountsExpectedToGoToBankQueryOptions(
    bankUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listPendingDepositsPerProduct = (
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<IncomingInstructionsResponse[]>({
    url: `/operations/own/insights/incoming-instructions/${bankUid}/pending`,
    method: 'GET',
    signal,
  });
};

export const getListPendingDepositsPerProductQueryKey = (bankUid: string) => {
  return [
    `/operations/own/insights/incoming-instructions/${bankUid}/pending`,
  ] as const;
};

export const getListPendingDepositsPerProductQueryOptions = <
  TData = Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListPendingDepositsPerProductQueryKey(bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPendingDepositsPerProduct>>
  > = ({ signal }) => listPendingDepositsPerProduct(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPendingDepositsPerProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPendingDepositsPerProduct>>
>;
export type ListPendingDepositsPerProductQueryError = unknown;

export const useListPendingDepositsPerProduct = <
  TData = Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPendingDepositsPerProductQueryOptions(
    bankUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listUpcomingNoticeWithdrawals = (
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<UpcomingNoticeWithdrawalsResponse[]>({
    url: `/operations/own/insights/notice-withdrawals/${bankUid}`,
    method: 'GET',
    signal,
  });
};

export const getListUpcomingNoticeWithdrawalsQueryKey = (bankUid: string) => {
  return [`/operations/own/insights/notice-withdrawals/${bankUid}`] as const;
};

export const getListUpcomingNoticeWithdrawalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListUpcomingNoticeWithdrawalsQueryKey(bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>
  > = ({ signal }) => listUpcomingNoticeWithdrawals(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListUpcomingNoticeWithdrawalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>
>;
export type ListUpcomingNoticeWithdrawalsQueryError = unknown;

export const useListUpcomingNoticeWithdrawals = <
  TData = Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListUpcomingNoticeWithdrawalsQueryOptions(
    bankUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listUpcomingMaturities = (
  bankUid: string,
  params?: ListUpcomingMaturitiesParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageUpcomingMaturitiesPaginatedRecord>({
    url: `/operations/own/insights/upcoming-maturities/${bankUid}`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListUpcomingMaturitiesQueryKey = (
  bankUid: string,
  params?: ListUpcomingMaturitiesParams
) => {
  return [
    `/operations/own/insights/upcoming-maturities/${bankUid}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListUpcomingMaturitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof listUpcomingMaturities>>,
  TError = unknown
>(
  bankUid: string,
  params?: ListUpcomingMaturitiesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listUpcomingMaturities>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListUpcomingMaturitiesQueryKey(bankUid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listUpcomingMaturities>>
  > = ({ signal }) => listUpcomingMaturities(bankUid, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listUpcomingMaturities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListUpcomingMaturitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpcomingMaturities>>
>;
export type ListUpcomingMaturitiesQueryError = unknown;

export const useListUpcomingMaturities = <
  TData = Awaited<ReturnType<typeof listUpcomingMaturities>>,
  TError = unknown
>(
  bankUid: string,
  params?: ListUpcomingMaturitiesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listUpcomingMaturities>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListUpcomingMaturitiesQueryOptions(
    bankUid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
