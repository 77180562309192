/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PartnerBankIntegrationType =
  typeof PartnerBankIntegrationType[keyof typeof PartnerBankIntegrationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerBankIntegrationType = {
  NONE: 'NONE',
  EMAIL: 'EMAIL',
  API: 'API',
  EMAIL_AND_API: 'EMAIL_AND_API',
} as const;
