/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankReconciliationConfirmRequest,
  BankReconciliationUnconfirmedResponse,
  BankReconciliationUpdateRequest,
  BondsmithPageBankReconciliationConfirmedResponse,
  FetchConfirmedBankReconciliationsParams,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const confirmBankReconciliation = (
  bankReconciliationConfirmRequest: BankReconciliationConfirmRequest
) => {
  return customInstance<void>({
    url: `/operations/bank/recon/confirm`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bankReconciliationConfirmRequest,
  });
};

export const getConfirmBankReconciliationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmBankReconciliation>>,
    TError,
    { data: BankReconciliationConfirmRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmBankReconciliation>>,
  TError,
  { data: BankReconciliationConfirmRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmBankReconciliation>>,
    { data: BankReconciliationConfirmRequest }
  > = (props) => {
    const { data } = props ?? {};

    return confirmBankReconciliation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmBankReconciliationMutationResult = NonNullable<
  Awaited<ReturnType<typeof confirmBankReconciliation>>
>;
export type ConfirmBankReconciliationMutationBody =
  BankReconciliationConfirmRequest;
export type ConfirmBankReconciliationMutationError = unknown;

export const useConfirmBankReconciliation = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmBankReconciliation>>,
    TError,
    { data: BankReconciliationConfirmRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof confirmBankReconciliation>>,
  TError,
  { data: BankReconciliationConfirmRequest },
  TContext
> => {
  const mutationOptions = getConfirmBankReconciliationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchConfirmedBankReconciliations = (
  params?: FetchConfirmedBankReconciliationsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageBankReconciliationConfirmedResponse>({
    url: `/operations/bank/recon/confirmed`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchConfirmedBankReconciliationsQueryKey = (
  params?: FetchConfirmedBankReconciliationsParams
) => {
  return [
    `/operations/bank/recon/confirmed`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchConfirmedBankReconciliationsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchConfirmedBankReconciliations>>,
  TError = unknown
>(
  params?: FetchConfirmedBankReconciliationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchConfirmedBankReconciliations>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchConfirmedBankReconciliationsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchConfirmedBankReconciliations>>
  > = ({ signal }) => fetchConfirmedBankReconciliations(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchConfirmedBankReconciliations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchConfirmedBankReconciliationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchConfirmedBankReconciliations>>
>;
export type FetchConfirmedBankReconciliationsQueryError = unknown;

export const useFetchConfirmedBankReconciliations = <
  TData = Awaited<ReturnType<typeof fetchConfirmedBankReconciliations>>,
  TError = unknown
>(
  params?: FetchConfirmedBankReconciliationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchConfirmedBankReconciliations>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchConfirmedBankReconciliationsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchUnconfirmedBankReconciliations = (signal?: AbortSignal) => {
  return customInstance<BankReconciliationUnconfirmedResponse[]>({
    url: `/operations/bank/recon/unconfirmed`,
    method: 'GET',
    signal,
  });
};

export const getFetchUnconfirmedBankReconciliationsQueryKey = () => {
  return [`/operations/bank/recon/unconfirmed`] as const;
};

export const getFetchUnconfirmedBankReconciliationsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchUnconfirmedBankReconciliations>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchUnconfirmedBankReconciliations>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchUnconfirmedBankReconciliationsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchUnconfirmedBankReconciliations>>
  > = ({ signal }) => fetchUnconfirmedBankReconciliations(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchUnconfirmedBankReconciliations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchUnconfirmedBankReconciliationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchUnconfirmedBankReconciliations>>
>;
export type FetchUnconfirmedBankReconciliationsQueryError = unknown;

export const useFetchUnconfirmedBankReconciliations = <
  TData = Awaited<ReturnType<typeof fetchUnconfirmedBankReconciliations>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchUnconfirmedBankReconciliations>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getFetchUnconfirmedBankReconciliationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateBankReconciliation = (
  uid: string,
  bankReconciliationUpdateRequest: BankReconciliationUpdateRequest
) => {
  return customInstance<void>({
    url: `/operations/bank/recon/update/${uid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: bankReconciliationUpdateRequest,
  });
};

export const getUpdateBankReconciliationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBankReconciliation>>,
    TError,
    { uid: string; data: BankReconciliationUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateBankReconciliation>>,
  TError,
  { uid: string; data: BankReconciliationUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBankReconciliation>>,
    { uid: string; data: BankReconciliationUpdateRequest }
  > = (props) => {
    const { uid, data } = props ?? {};

    return updateBankReconciliation(uid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBankReconciliationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateBankReconciliation>>
>;
export type UpdateBankReconciliationMutationBody =
  BankReconciliationUpdateRequest;
export type UpdateBankReconciliationMutationError = unknown;

export const useUpdateBankReconciliation = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBankReconciliation>>,
    TError,
    { uid: string; data: BankReconciliationUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateBankReconciliation>>,
  TError,
  { uid: string; data: BankReconciliationUpdateRequest },
  TContext
> => {
  const mutationOptions = getUpdateBankReconciliationMutationOptions(options);

  return useMutation(mutationOptions);
};
