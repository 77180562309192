/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageAggregatePaymentDueResponse,
  BondsmithPageFundingRecordResponse,
  BondsmithPageOrderAdjustmentPaginatedResponse,
  BondsmithPagePaymentMatchingResponse,
  ConnectorType,
  CurrencyCode,
  GetOrderExpectedAdjustmentsParams,
  GetPaymentMatchingHistoryParams,
  GetScheduledPaymentsDueParams,
  ListFundingRecordsParams,
  ListPaymentsDueParams,
  PartnerClearingPaymentDueResponse,
  PartnerType,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getOrderExpectedAdjustments = (
  params?: GetOrderExpectedAdjustmentsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageOrderAdjustmentPaginatedResponse>({
    url: `/operations/own/settlement/adjustment`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetOrderExpectedAdjustmentsQueryKey = (
  params?: GetOrderExpectedAdjustmentsParams
) => {
  return [
    `/operations/own/settlement/adjustment`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetOrderExpectedAdjustmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderExpectedAdjustments>>,
  TError = unknown
>(
  params?: GetOrderExpectedAdjustmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrderExpectedAdjustments>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOrderExpectedAdjustmentsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrderExpectedAdjustments>>
  > = ({ signal }) => getOrderExpectedAdjustments(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderExpectedAdjustments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderExpectedAdjustmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrderExpectedAdjustments>>
>;
export type GetOrderExpectedAdjustmentsQueryError = unknown;

export const useGetOrderExpectedAdjustments = <
  TData = Awaited<ReturnType<typeof getOrderExpectedAdjustments>>,
  TError = unknown
>(
  params?: GetOrderExpectedAdjustmentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrderExpectedAdjustments>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrderExpectedAdjustmentsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listPaymentsDue = (
  params?: ListPaymentsDueParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageAggregatePaymentDueResponse>({
    url: `/operations/own/settlement/due`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListPaymentsDueQueryKey = (params?: ListPaymentsDueParams) => {
  return [
    `/operations/own/settlement/due`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListPaymentsDueQueryOptions = <
  TData = Awaited<ReturnType<typeof listPaymentsDue>>,
  TError = unknown
>(
  params?: ListPaymentsDueParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPaymentsDue>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPaymentsDueQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPaymentsDue>>> = ({
    signal,
  }) => listPaymentsDue(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPaymentsDue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPaymentsDueQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPaymentsDue>>
>;
export type ListPaymentsDueQueryError = unknown;

export const useListPaymentsDue = <
  TData = Awaited<ReturnType<typeof listPaymentsDue>>,
  TError = unknown
>(
  params?: ListPaymentsDueParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPaymentsDue>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPaymentsDueQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchUnlinkedByPartnerUid = (
  partnerType: PartnerType,
  partnerUid: string,
  currencyCode: CurrencyCode,
  connectorType: ConnectorType,
  signal?: AbortSignal
) => {
  return customInstance<PartnerClearingPaymentDueResponse>({
    url: `/operations/own/settlement/due/partner/${partnerType}/${partnerUid}/${currencyCode}/${connectorType}/unlinked`,
    method: 'GET',
    signal,
  });
};

export const getFetchUnlinkedByPartnerUidQueryKey = (
  partnerType: PartnerType,
  partnerUid: string,
  currencyCode: CurrencyCode,
  connectorType: ConnectorType
) => {
  return [
    `/operations/own/settlement/due/partner/${partnerType}/${partnerUid}/${currencyCode}/${connectorType}/unlinked`,
  ] as const;
};

export const getFetchUnlinkedByPartnerUidQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchUnlinkedByPartnerUid>>,
  TError = unknown
>(
  partnerType: PartnerType,
  partnerUid: string,
  currencyCode: CurrencyCode,
  connectorType: ConnectorType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchUnlinkedByPartnerUid>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchUnlinkedByPartnerUidQueryKey(
      partnerType,
      partnerUid,
      currencyCode,
      connectorType
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchUnlinkedByPartnerUid>>
  > = ({ signal }) =>
    fetchUnlinkedByPartnerUid(
      partnerType,
      partnerUid,
      currencyCode,
      connectorType,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(partnerType && partnerUid && currencyCode && connectorType),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchUnlinkedByPartnerUid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchUnlinkedByPartnerUidQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchUnlinkedByPartnerUid>>
>;
export type FetchUnlinkedByPartnerUidQueryError = unknown;

export const useFetchUnlinkedByPartnerUid = <
  TData = Awaited<ReturnType<typeof fetchUnlinkedByPartnerUid>>,
  TError = unknown
>(
  partnerType: PartnerType,
  partnerUid: string,
  currencyCode: CurrencyCode,
  connectorType: ConnectorType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchUnlinkedByPartnerUid>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchUnlinkedByPartnerUidQueryOptions(
    partnerType,
    partnerUid,
    currencyCode,
    connectorType,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listFundingRecords = (
  params?: ListFundingRecordsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageFundingRecordResponse>({
    url: `/operations/own/settlement/funding`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListFundingRecordsQueryKey = (
  params?: ListFundingRecordsParams
) => {
  return [
    `/operations/own/settlement/funding`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListFundingRecordsQueryOptions = <
  TData = Awaited<ReturnType<typeof listFundingRecords>>,
  TError = unknown
>(
  params?: ListFundingRecordsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listFundingRecords>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListFundingRecordsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listFundingRecords>>> =
    ({ signal }) => listFundingRecords(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listFundingRecords>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListFundingRecordsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listFundingRecords>>
>;
export type ListFundingRecordsQueryError = unknown;

export const useListFundingRecords = <
  TData = Awaited<ReturnType<typeof listFundingRecords>>,
  TError = unknown
>(
  params?: ListFundingRecordsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listFundingRecords>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFundingRecordsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPaymentMatchingHistory = (
  params?: GetPaymentMatchingHistoryParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePaymentMatchingResponse>({
    url: `/operations/own/settlement/history`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetPaymentMatchingHistoryQueryKey = (
  params?: GetPaymentMatchingHistoryParams
) => {
  return [
    `/operations/own/settlement/history`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetPaymentMatchingHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentMatchingHistory>>,
  TError = unknown
>(
  params?: GetPaymentMatchingHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMatchingHistory>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPaymentMatchingHistoryQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPaymentMatchingHistory>>
  > = ({ signal }) => getPaymentMatchingHistory(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPaymentMatchingHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPaymentMatchingHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentMatchingHistory>>
>;
export type GetPaymentMatchingHistoryQueryError = unknown;

export const useGetPaymentMatchingHistory = <
  TData = Awaited<ReturnType<typeof getPaymentMatchingHistory>>,
  TError = unknown
>(
  params?: GetPaymentMatchingHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPaymentMatchingHistory>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentMatchingHistoryQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getScheduledPaymentsDue = (
  params?: GetScheduledPaymentsDueParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePaymentMatchingResponse>({
    url: `/operations/own/settlement/scheduled`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetScheduledPaymentsDueQueryKey = (
  params?: GetScheduledPaymentsDueParams
) => {
  return [
    `/operations/own/settlement/scheduled`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetScheduledPaymentsDueQueryOptions = <
  TData = Awaited<ReturnType<typeof getScheduledPaymentsDue>>,
  TError = unknown
>(
  params?: GetScheduledPaymentsDueParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScheduledPaymentsDue>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetScheduledPaymentsDueQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getScheduledPaymentsDue>>
  > = ({ signal }) => getScheduledPaymentsDue(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getScheduledPaymentsDue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetScheduledPaymentsDueQueryResult = NonNullable<
  Awaited<ReturnType<typeof getScheduledPaymentsDue>>
>;
export type GetScheduledPaymentsDueQueryError = unknown;

export const useGetScheduledPaymentsDue = <
  TData = Awaited<ReturnType<typeof getScheduledPaymentsDue>>,
  TError = unknown
>(
  params?: GetScheduledPaymentsDueParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScheduledPaymentsDue>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetScheduledPaymentsDueQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
