/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageRejectedTransactionResponse,
  ListFlaggedTransactionsNewParams,
  RejectedTransactionResolutionRequests,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listFlaggedTransactionsNew = (
  params?: ListFlaggedTransactionsNewParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageRejectedTransactionResponse>({
    url: `/transactions/flagged`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListFlaggedTransactionsNewQueryKey = (
  params?: ListFlaggedTransactionsNewParams
) => {
  return [`/transactions/flagged`, ...(params ? [params] : [])] as const;
};

export const getListFlaggedTransactionsNewQueryOptions = <
  TData = Awaited<ReturnType<typeof listFlaggedTransactionsNew>>,
  TError = unknown
>(
  params?: ListFlaggedTransactionsNewParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listFlaggedTransactionsNew>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListFlaggedTransactionsNewQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listFlaggedTransactionsNew>>
  > = ({ signal }) => listFlaggedTransactionsNew(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listFlaggedTransactionsNew>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListFlaggedTransactionsNewQueryResult = NonNullable<
  Awaited<ReturnType<typeof listFlaggedTransactionsNew>>
>;
export type ListFlaggedTransactionsNewQueryError = unknown;

export const useListFlaggedTransactionsNew = <
  TData = Awaited<ReturnType<typeof listFlaggedTransactionsNew>>,
  TError = unknown
>(
  params?: ListFlaggedTransactionsNewParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listFlaggedTransactionsNew>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFlaggedTransactionsNewQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const processRejectedTransactions = (
  rejectedTransactionResolutionRequests: RejectedTransactionResolutionRequests
) => {
  return customInstance<void>({
    url: `/transactions/rejected`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: rejectedTransactionResolutionRequests,
  });
};

export const getProcessRejectedTransactionsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processRejectedTransactions>>,
    TError,
    { data: RejectedTransactionResolutionRequests },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processRejectedTransactions>>,
  TError,
  { data: RejectedTransactionResolutionRequests },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processRejectedTransactions>>,
    { data: RejectedTransactionResolutionRequests }
  > = (props) => {
    const { data } = props ?? {};

    return processRejectedTransactions(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessRejectedTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof processRejectedTransactions>>
>;
export type ProcessRejectedTransactionsMutationBody =
  RejectedTransactionResolutionRequests;
export type ProcessRejectedTransactionsMutationError = unknown;

export const useProcessRejectedTransactions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processRejectedTransactions>>,
    TError,
    { data: RejectedTransactionResolutionRequests },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processRejectedTransactions>>,
  TError,
  { data: RejectedTransactionResolutionRequests },
  TContext
> => {
  const mutationOptions =
    getProcessRejectedTransactionsMutationOptions(options);

  return useMutation(mutationOptions);
};
