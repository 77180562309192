/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalStatus = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const;
