/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type CustomerPaginatedProperty =
  typeof CustomerPaginatedProperty[keyof typeof CustomerPaginatedProperty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomerPaginatedProperty = {
  CREATED_AT: 'CREATED_AT',
  SID: 'SID',
} as const;
