/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PersonKycDocumentStatus =
  typeof PersonKycDocumentStatus[keyof typeof PersonKycDocumentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonKycDocumentStatus = {
  RECEIVED: 'RECEIVED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
} as const;
