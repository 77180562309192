/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalCustomerStatus =
  typeof InternalCustomerStatus[keyof typeof InternalCustomerStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalCustomerStatus = {
  PENDING: 'PENDING',
  ACTIVATED: 'ACTIVATED',
  DEACTIVATED: 'DEACTIVATED',
  CANCELLED: 'CANCELLED',
  CLOSED: 'CLOSED',
} as const;
