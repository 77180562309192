/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type { DailyOrderCheckAndScheduleResponse } from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const runAllDailyChecksForPlatform = (platformUid: string) => {
  return customInstance<void>({
    url: `/operations/daily-checks/${platformUid}/all`,
    method: 'POST',
  });
};

export const getRunAllDailyChecksForPlatformMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllDailyChecksForPlatform>>,
    TError,
    { platformUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runAllDailyChecksForPlatform>>,
  TError,
  { platformUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runAllDailyChecksForPlatform>>,
    { platformUid: string }
  > = (props) => {
    const { platformUid } = props ?? {};

    return runAllDailyChecksForPlatform(platformUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type RunAllDailyChecksForPlatformMutationResult = NonNullable<
  Awaited<ReturnType<typeof runAllDailyChecksForPlatform>>
>;

export type RunAllDailyChecksForPlatformMutationError = unknown;

export const useRunAllDailyChecksForPlatform = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllDailyChecksForPlatform>>,
    TError,
    { platformUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runAllDailyChecksForPlatform>>,
  TError,
  { platformUid: string },
  TContext
> => {
  const mutationOptions =
    getRunAllDailyChecksForPlatformMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchLatestChecksForPlatform = (
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<DailyOrderCheckAndScheduleResponse>({
    url: `/operations/daily-checks/${platformUid}/latest`,
    method: 'GET',
    signal,
  });
};

export const getFetchLatestChecksForPlatformQueryKey = (
  platformUid: string
) => {
  return [`/operations/daily-checks/${platformUid}/latest`] as const;
};

export const getFetchLatestChecksForPlatformQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchLatestChecksForPlatform>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchLatestChecksForPlatform>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchLatestChecksForPlatformQueryKey(platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchLatestChecksForPlatform>>
  > = ({ signal }) => fetchLatestChecksForPlatform(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchLatestChecksForPlatform>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchLatestChecksForPlatformQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchLatestChecksForPlatform>>
>;
export type FetchLatestChecksForPlatformQueryError = unknown;

export const useFetchLatestChecksForPlatform = <
  TData = Awaited<ReturnType<typeof fetchLatestChecksForPlatform>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchLatestChecksForPlatform>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchLatestChecksForPlatformQueryOptions(
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
