/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  ListUsersParams,
  PortalUserCreateRequest,
  PortalUserResponse,
  PortalUserUpdateRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listUsers = (params?: ListUsersParams, signal?: AbortSignal) => {
  return customInstance<PortalUserResponse[]>({
    url: `/operations/user`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListUsersQueryKey = (params?: ListUsersParams) => {
  return [`/operations/user`, ...(params ? [params] : [])] as const;
};

export const getListUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof listUsers>>,
  TError = unknown
>(
  params?: ListUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listUsers>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listUsers>>> = ({
    signal,
  }) => listUsers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUsers>>
>;
export type ListUsersQueryError = unknown;

export const useListUsers = <
  TData = Awaited<ReturnType<typeof listUsers>>,
  TError = unknown
>(
  params?: ListUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listUsers>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createUser = (
  portalUserCreateRequest: PortalUserCreateRequest
) => {
  return customInstance<PortalUserResponse>({
    url: `/operations/user`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: portalUserCreateRequest,
  });
};

export const getCreateUserMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: PortalUserCreateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUser>>,
  TError,
  { data: PortalUserCreateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUser>>,
    { data: PortalUserCreateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUser>>
>;
export type CreateUserMutationBody = PortalUserCreateRequest;
export type CreateUserMutationError = unknown;

export const useCreateUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: PortalUserCreateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createUser>>,
  TError,
  { data: PortalUserCreateRequest },
  TContext
> => {
  const mutationOptions = getCreateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchUser = (userUid: string, signal?: AbortSignal) => {
  return customInstance<PortalUserResponse>({
    url: `/operations/user/${userUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchUserQueryKey = (userUid: string) => {
  return [`/operations/user/${userUid}`] as const;
};

export const getFetchUserQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchUser>>,
  TError = unknown
>(
  userUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchUser>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchUserQueryKey(userUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchUser>>> = ({
    signal,
  }) => fetchUser(userUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userUid,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof fetchUser>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type FetchUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchUser>>
>;
export type FetchUserQueryError = unknown;

export const useFetchUser = <
  TData = Awaited<ReturnType<typeof fetchUser>>,
  TError = unknown
>(
  userUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchUser>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchUserQueryOptions(userUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateUser = (
  userUid: string,
  portalUserUpdateRequest: PortalUserUpdateRequest
) => {
  return customInstance<void>({
    url: `/operations/user/${userUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: portalUserUpdateRequest,
  });
};

export const getUpdateUserMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { userUid: string; data: PortalUserUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { userUid: string; data: PortalUserUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { userUid: string; data: PortalUserUpdateRequest }
  > = (props) => {
    const { userUid, data } = props ?? {};

    return updateUser(userUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUser>>
>;
export type UpdateUserMutationBody = PortalUserUpdateRequest;
export type UpdateUserMutationError = unknown;

export const useUpdateUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { userUid: string; data: PortalUserUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { userUid: string; data: PortalUserUpdateRequest },
  TContext
> => {
  const mutationOptions = getUpdateUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteUser = (userUid: string) => {
  return customInstance<void>({
    url: `/operations/user/${userUid}`,
    method: 'DELETE',
  });
};

export const getDeleteUserMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { userUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUser>>,
  TError,
  { userUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUser>>,
    { userUid: string }
  > = (props) => {
    const { userUid } = props ?? {};

    return deleteUser(userUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUser>>
>;

export type DeleteUserMutationError = unknown;

export const useDeleteUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { userUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUser>>,
  TError,
  { userUid: string },
  TContext
> => {
  const mutationOptions = getDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const disableUser = (userUid: string) => {
  return customInstance<void>({
    url: `/operations/user/${userUid}/disable`,
    method: 'PATCH',
  });
};

export const getDisableUserMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disableUser>>,
    TError,
    { userUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof disableUser>>,
  TError,
  { userUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof disableUser>>,
    { userUid: string }
  > = (props) => {
    const { userUid } = props ?? {};

    return disableUser(userUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type DisableUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof disableUser>>
>;

export type DisableUserMutationError = unknown;

export const useDisableUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof disableUser>>,
    TError,
    { userUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof disableUser>>,
  TError,
  { userUid: string },
  TContext
> => {
  const mutationOptions = getDisableUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const enableUser = (userUid: string) => {
  return customInstance<void>({
    url: `/operations/user/${userUid}/enable`,
    method: 'PATCH',
  });
};

export const getEnableUserMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof enableUser>>,
    TError,
    { userUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof enableUser>>,
  TError,
  { userUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof enableUser>>,
    { userUid: string }
  > = (props) => {
    const { userUid } = props ?? {};

    return enableUser(userUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type EnableUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof enableUser>>
>;

export type EnableUserMutationError = unknown;

export const useEnableUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof enableUser>>,
    TError,
    { userUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof enableUser>>,
  TError,
  { userUid: string },
  TContext
> => {
  const mutationOptions = getEnableUserMutationOptions(options);

  return useMutation(mutationOptions);
};
