/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankReconciliationInstantNoticeHistoryResponse,
  BankReconciliationInstantNoticeResponse,
  BankReconciliationInstantNoticeUpdateRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const fetchInstantNoticeReconciliations = (signal?: AbortSignal) => {
  return customInstance<BankReconciliationInstantNoticeResponse[]>({
    url: `/operations/bank/recon/instant-notice`,
    method: 'GET',
    signal,
  });
};

export const getFetchInstantNoticeReconciliationsQueryKey = () => {
  return [`/operations/bank/recon/instant-notice`] as const;
};

export const getFetchInstantNoticeReconciliationsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchInstantNoticeReconciliations>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchInstantNoticeReconciliations>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchInstantNoticeReconciliationsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchInstantNoticeReconciliations>>
  > = ({ signal }) => fetchInstantNoticeReconciliations(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchInstantNoticeReconciliations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchInstantNoticeReconciliationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchInstantNoticeReconciliations>>
>;
export type FetchInstantNoticeReconciliationsQueryError = unknown;

export const useFetchInstantNoticeReconciliations = <
  TData = Awaited<ReturnType<typeof fetchInstantNoticeReconciliations>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchInstantNoticeReconciliations>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getFetchInstantNoticeReconciliationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchInstantNoticeReconciliationHistory = (
  uid: string,
  signal?: AbortSignal
) => {
  return customInstance<BankReconciliationInstantNoticeHistoryResponse[]>({
    url: `/operations/bank/recon/instant-notice/history/${uid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchInstantNoticeReconciliationHistoryQueryKey = (
  uid: string
) => {
  return [`/operations/bank/recon/instant-notice/history/${uid}`] as const;
};

export const getFetchInstantNoticeReconciliationHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchInstantNoticeReconciliationHistory>>,
  TError = unknown
>(
  uid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchInstantNoticeReconciliationHistory>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchInstantNoticeReconciliationHistoryQueryKey(uid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchInstantNoticeReconciliationHistory>>
  > = ({ signal }) => fetchInstantNoticeReconciliationHistory(uid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!uid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchInstantNoticeReconciliationHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchInstantNoticeReconciliationHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchInstantNoticeReconciliationHistory>>
>;
export type FetchInstantNoticeReconciliationHistoryQueryError = unknown;

export const useFetchInstantNoticeReconciliationHistory = <
  TData = Awaited<ReturnType<typeof fetchInstantNoticeReconciliationHistory>>,
  TError = unknown
>(
  uid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchInstantNoticeReconciliationHistory>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchInstantNoticeReconciliationHistoryQueryOptions(
    uid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const matchReconciliation = (
  productUid: string,
  bankReconciliationInstantNoticeUpdateRequest: BankReconciliationInstantNoticeUpdateRequest
) => {
  return customInstance<void>({
    url: `/operations/bank/recon/instant-notice/match/${productUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: bankReconciliationInstantNoticeUpdateRequest,
  });
};

export const getMatchReconciliationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof matchReconciliation>>,
    TError,
    { productUid: string; data: BankReconciliationInstantNoticeUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof matchReconciliation>>,
  TError,
  { productUid: string; data: BankReconciliationInstantNoticeUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof matchReconciliation>>,
    { productUid: string; data: BankReconciliationInstantNoticeUpdateRequest }
  > = (props) => {
    const { productUid, data } = props ?? {};

    return matchReconciliation(productUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MatchReconciliationMutationResult = NonNullable<
  Awaited<ReturnType<typeof matchReconciliation>>
>;
export type MatchReconciliationMutationBody =
  BankReconciliationInstantNoticeUpdateRequest;
export type MatchReconciliationMutationError = unknown;

export const useMatchReconciliation = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof matchReconciliation>>,
    TError,
    { productUid: string; data: BankReconciliationInstantNoticeUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof matchReconciliation>>,
  TError,
  { productUid: string; data: BankReconciliationInstantNoticeUpdateRequest },
  TContext
> => {
  const mutationOptions = getMatchReconciliationMutationOptions(options);

  return useMutation(mutationOptions);
};
