/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type ExternalPaymentStateType =
  typeof ExternalPaymentStateType[keyof typeof ExternalPaymentStateType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalPaymentStateType = {
  PROCESSING: 'PROCESSING',
  ACCEPTED: 'ACCEPTED',
  ACCEPTED_WHITELISTED: 'ACCEPTED_WHITELISTED',
  REJECTED: 'REJECTED',
  RETURNED: 'RETURNED',
  FLAGGED: 'FLAGGED',
} as const;
