/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation } from 'react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  PaymentDueRecoveryRequest,
  PaymentMatchingByActualPaymentRequest,
  PaymentMatchingByPaymentDueRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const autoMatchPayment = () => {
  return customInstance<void>({
    url: `/operations/own/matching/auto`,
    method: 'POST',
  });
};

export const getAutoMatchPaymentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof autoMatchPayment>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof autoMatchPayment>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof autoMatchPayment>>,
    void
  > = () => {
    return autoMatchPayment();
  };

  return { mutationFn, ...mutationOptions };
};

export type AutoMatchPaymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof autoMatchPayment>>
>;

export type AutoMatchPaymentMutationError = unknown;

export const useAutoMatchPayment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof autoMatchPayment>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof autoMatchPayment>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAutoMatchPaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const matchPaymentsByActualPayment = (
  partner: string,
  paymentMatchingByActualPaymentRequest: PaymentMatchingByActualPaymentRequest
) => {
  return customInstance<void>({
    url: `/operations/own/matching/partner/${partner}/actualPayment`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: paymentMatchingByActualPaymentRequest,
  });
};

export const getMatchPaymentsByActualPaymentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof matchPaymentsByActualPayment>>,
    TError,
    { partner: string; data: PaymentMatchingByActualPaymentRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof matchPaymentsByActualPayment>>,
  TError,
  { partner: string; data: PaymentMatchingByActualPaymentRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof matchPaymentsByActualPayment>>,
    { partner: string; data: PaymentMatchingByActualPaymentRequest }
  > = (props) => {
    const { partner, data } = props ?? {};

    return matchPaymentsByActualPayment(partner, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MatchPaymentsByActualPaymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof matchPaymentsByActualPayment>>
>;
export type MatchPaymentsByActualPaymentMutationBody =
  PaymentMatchingByActualPaymentRequest;
export type MatchPaymentsByActualPaymentMutationError = unknown;

export const useMatchPaymentsByActualPayment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof matchPaymentsByActualPayment>>,
    TError,
    { partner: string; data: PaymentMatchingByActualPaymentRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof matchPaymentsByActualPayment>>,
  TError,
  { partner: string; data: PaymentMatchingByActualPaymentRequest },
  TContext
> => {
  const mutationOptions =
    getMatchPaymentsByActualPaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const matchPaymentsByPaymentDue = (
  partner: string,
  paymentMatchingByPaymentDueRequest: PaymentMatchingByPaymentDueRequest
) => {
  return customInstance<void>({
    url: `/operations/own/matching/partner/${partner}/paymentDue`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: paymentMatchingByPaymentDueRequest,
  });
};

export const getMatchPaymentsByPaymentDueMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof matchPaymentsByPaymentDue>>,
    TError,
    { partner: string; data: PaymentMatchingByPaymentDueRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof matchPaymentsByPaymentDue>>,
  TError,
  { partner: string; data: PaymentMatchingByPaymentDueRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof matchPaymentsByPaymentDue>>,
    { partner: string; data: PaymentMatchingByPaymentDueRequest }
  > = (props) => {
    const { partner, data } = props ?? {};

    return matchPaymentsByPaymentDue(partner, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MatchPaymentsByPaymentDueMutationResult = NonNullable<
  Awaited<ReturnType<typeof matchPaymentsByPaymentDue>>
>;
export type MatchPaymentsByPaymentDueMutationBody =
  PaymentMatchingByPaymentDueRequest;
export type MatchPaymentsByPaymentDueMutationError = unknown;

export const useMatchPaymentsByPaymentDue = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof matchPaymentsByPaymentDue>>,
    TError,
    { partner: string; data: PaymentMatchingByPaymentDueRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof matchPaymentsByPaymentDue>>,
  TError,
  { partner: string; data: PaymentMatchingByPaymentDueRequest },
  TContext
> => {
  const mutationOptions = getMatchPaymentsByPaymentDueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const recoverMatchedPaymentDue = (
  partner: string,
  paymentDueRecoveryRequest: PaymentDueRecoveryRequest
) => {
  return customInstance<void>({
    url: `/operations/own/matching/partner/${partner}/paymentDue/recover`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: paymentDueRecoveryRequest,
  });
};

export const getRecoverMatchedPaymentDueMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recoverMatchedPaymentDue>>,
    TError,
    { partner: string; data: PaymentDueRecoveryRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recoverMatchedPaymentDue>>,
  TError,
  { partner: string; data: PaymentDueRecoveryRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recoverMatchedPaymentDue>>,
    { partner: string; data: PaymentDueRecoveryRequest }
  > = (props) => {
    const { partner, data } = props ?? {};

    return recoverMatchedPaymentDue(partner, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RecoverMatchedPaymentDueMutationResult = NonNullable<
  Awaited<ReturnType<typeof recoverMatchedPaymentDue>>
>;
export type RecoverMatchedPaymentDueMutationBody = PaymentDueRecoveryRequest;
export type RecoverMatchedPaymentDueMutationError = unknown;

export const useRecoverMatchedPaymentDue = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recoverMatchedPaymentDue>>,
    TError,
    { partner: string; data: PaymentDueRecoveryRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof recoverMatchedPaymentDue>>,
  TError,
  { partner: string; data: PaymentDueRecoveryRequest },
  TContext
> => {
  const mutationOptions = getRecoverMatchedPaymentDueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const resetFailedPayment = (
  partner: string,
  aggregatePaymentDueUid: string
) => {
  return customInstance<void>({
    url: `/operations/own/matching/partner/${partner}/paymentDue/${aggregatePaymentDueUid}/retry`,
    method: 'PUT',
  });
};

export const getResetFailedPaymentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetFailedPayment>>,
    TError,
    { partner: string; aggregatePaymentDueUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resetFailedPayment>>,
  TError,
  { partner: string; aggregatePaymentDueUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetFailedPayment>>,
    { partner: string; aggregatePaymentDueUid: string }
  > = (props) => {
    const { partner, aggregatePaymentDueUid } = props ?? {};

    return resetFailedPayment(partner, aggregatePaymentDueUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetFailedPaymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetFailedPayment>>
>;

export type ResetFailedPaymentMutationError = unknown;

export const useResetFailedPayment = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetFailedPayment>>,
    TError,
    { partner: string; aggregatePaymentDueUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof resetFailedPayment>>,
  TError,
  { partner: string; aggregatePaymentDueUid: string },
  TContext
> => {
  const mutationOptions = getResetFailedPaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
