/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type AccountEntityType =
  typeof AccountEntityType[keyof typeof AccountEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountEntityType = {
  PLATFORM: 'PLATFORM',
  BANK: 'BANK',
  CUSTOMER: 'CUSTOMER',
  OPERATIONAL: 'OPERATIONAL',
  FEE: 'FEE',
  FUNDING: 'FUNDING',
} as const;
