/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalOrderType =
  typeof InternalOrderType[keyof typeof InternalOrderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalOrderType = {
  DEPOSIT: 'DEPOSIT',
  PARTIAL_WITHDRAWAL: 'PARTIAL_WITHDRAWAL',
  FULL_WITHDRAWAL: 'FULL_WITHDRAWAL',
  ORDER_FEE_WITHDRAWAL: 'ORDER_FEE_WITHDRAWAL',
  PRODUCT_TRANSFER_FROM: 'PRODUCT_TRANSFER_FROM',
  PRODUCT_TRANSFER_TO: 'PRODUCT_TRANSFER_TO',
} as const;
