/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  JobStatus,
  PartnerScheduleResponse,
  ScheduleEventType,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getScheduledBankEvents = (
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PartnerScheduleResponse[]>({
    url: `/operations/batch/processor/bank/${bankUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetScheduledBankEventsQueryKey = (bankUid: string) => {
  return [`/operations/batch/processor/bank/${bankUid}`] as const;
};

export const getGetScheduledBankEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof getScheduledBankEvents>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScheduledBankEvents>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetScheduledBankEventsQueryKey(bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getScheduledBankEvents>>
  > = ({ signal }) => getScheduledBankEvents(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getScheduledBankEvents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetScheduledBankEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getScheduledBankEvents>>
>;
export type GetScheduledBankEventsQueryError = unknown;

export const useGetScheduledBankEvents = <
  TData = Awaited<ReturnType<typeof getScheduledBankEvents>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScheduledBankEvents>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetScheduledBankEventsQueryOptions(bankUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const processBankScheduledTask = (
  bankUid: string,
  scheduleEventType: ScheduleEventType
) => {
  return customInstance<void>({
    url: `/operations/batch/processor/bank/${bankUid}/${scheduleEventType}`,
    method: 'POST',
  });
};

export const getProcessBankScheduledTaskMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processBankScheduledTask>>,
    TError,
    { bankUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processBankScheduledTask>>,
  TError,
  { bankUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processBankScheduledTask>>,
    { bankUid: string; scheduleEventType: ScheduleEventType }
  > = (props) => {
    const { bankUid, scheduleEventType } = props ?? {};

    return processBankScheduledTask(bankUid, scheduleEventType);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessBankScheduledTaskMutationResult = NonNullable<
  Awaited<ReturnType<typeof processBankScheduledTask>>
>;

export type ProcessBankScheduledTaskMutationError = unknown;

export const useProcessBankScheduledTask = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processBankScheduledTask>>,
    TError,
    { bankUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processBankScheduledTask>>,
  TError,
  { bankUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const mutationOptions = getProcessBankScheduledTaskMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updateBankTaskStatus = (
  bankUid: string,
  scheduleEventType: ScheduleEventType,
  jobStatus: JobStatus
) => {
  return customInstance<void>({
    url: `/operations/batch/processor/bank/${bankUid}/${scheduleEventType}/status/${jobStatus}`,
    method: 'POST',
  });
};

export const getUpdateBankTaskStatusMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBankTaskStatus>>,
    TError,
    {
      bankUid: string;
      scheduleEventType: ScheduleEventType;
      jobStatus: JobStatus;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateBankTaskStatus>>,
  TError,
  {
    bankUid: string;
    scheduleEventType: ScheduleEventType;
    jobStatus: JobStatus;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBankTaskStatus>>,
    {
      bankUid: string;
      scheduleEventType: ScheduleEventType;
      jobStatus: JobStatus;
    }
  > = (props) => {
    const { bankUid, scheduleEventType, jobStatus } = props ?? {};

    return updateBankTaskStatus(bankUid, scheduleEventType, jobStatus);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBankTaskStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateBankTaskStatus>>
>;

export type UpdateBankTaskStatusMutationError = unknown;

export const useUpdateBankTaskStatus = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBankTaskStatus>>,
    TError,
    {
      bankUid: string;
      scheduleEventType: ScheduleEventType;
      jobStatus: JobStatus;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateBankTaskStatus>>,
  TError,
  {
    bankUid: string;
    scheduleEventType: ScheduleEventType;
    jobStatus: JobStatus;
  },
  TContext
> => {
  const mutationOptions = getUpdateBankTaskStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
export const processBankScheduledTaskSync = (
  bankUid: string,
  scheduleEventType: ScheduleEventType
) => {
  return customInstance<void>({
    url: `/operations/batch/processor/bank/${bankUid}/${scheduleEventType}/sync`,
    method: 'POST',
  });
};

export const getProcessBankScheduledTaskSyncMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processBankScheduledTaskSync>>,
    TError,
    { bankUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processBankScheduledTaskSync>>,
  TError,
  { bankUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processBankScheduledTaskSync>>,
    { bankUid: string; scheduleEventType: ScheduleEventType }
  > = (props) => {
    const { bankUid, scheduleEventType } = props ?? {};

    return processBankScheduledTaskSync(bankUid, scheduleEventType);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessBankScheduledTaskSyncMutationResult = NonNullable<
  Awaited<ReturnType<typeof processBankScheduledTaskSync>>
>;

export type ProcessBankScheduledTaskSyncMutationError = unknown;

export const useProcessBankScheduledTaskSync = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processBankScheduledTaskSync>>,
    TError,
    { bankUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processBankScheduledTaskSync>>,
  TError,
  { bankUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const mutationOptions =
    getProcessBankScheduledTaskSyncMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getScheduledPlatformEvents = (
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PartnerScheduleResponse[]>({
    url: `/operations/batch/processor/platform/${platformUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetScheduledPlatformEventsQueryKey = (platformUid: string) => {
  return [`/operations/batch/processor/platform/${platformUid}`] as const;
};

export const getGetScheduledPlatformEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof getScheduledPlatformEvents>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScheduledPlatformEvents>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetScheduledPlatformEventsQueryKey(platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getScheduledPlatformEvents>>
  > = ({ signal }) => getScheduledPlatformEvents(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getScheduledPlatformEvents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetScheduledPlatformEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getScheduledPlatformEvents>>
>;
export type GetScheduledPlatformEventsQueryError = unknown;

export const useGetScheduledPlatformEvents = <
  TData = Awaited<ReturnType<typeof getScheduledPlatformEvents>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getScheduledPlatformEvents>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetScheduledPlatformEventsQueryOptions(
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const processPlatformScheduledTask = (
  platformUid: string,
  scheduleEventType: ScheduleEventType
) => {
  return customInstance<void>({
    url: `/operations/batch/processor/platform/${platformUid}/${scheduleEventType}`,
    method: 'POST',
  });
};

export const getProcessPlatformScheduledTaskMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processPlatformScheduledTask>>,
    TError,
    { platformUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processPlatformScheduledTask>>,
  TError,
  { platformUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processPlatformScheduledTask>>,
    { platformUid: string; scheduleEventType: ScheduleEventType }
  > = (props) => {
    const { platformUid, scheduleEventType } = props ?? {};

    return processPlatformScheduledTask(platformUid, scheduleEventType);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessPlatformScheduledTaskMutationResult = NonNullable<
  Awaited<ReturnType<typeof processPlatformScheduledTask>>
>;

export type ProcessPlatformScheduledTaskMutationError = unknown;

export const useProcessPlatformScheduledTask = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processPlatformScheduledTask>>,
    TError,
    { platformUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processPlatformScheduledTask>>,
  TError,
  { platformUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const mutationOptions =
    getProcessPlatformScheduledTaskMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePlatformTaskStatus = (
  platformUid: string,
  scheduleEventType: ScheduleEventType,
  jobStatus: JobStatus
) => {
  return customInstance<void>({
    url: `/operations/batch/processor/platform/${platformUid}/${scheduleEventType}/status/${jobStatus}`,
    method: 'POST',
  });
};

export const getUpdatePlatformTaskStatusMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatformTaskStatus>>,
    TError,
    {
      platformUid: string;
      scheduleEventType: ScheduleEventType;
      jobStatus: JobStatus;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlatformTaskStatus>>,
  TError,
  {
    platformUid: string;
    scheduleEventType: ScheduleEventType;
    jobStatus: JobStatus;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlatformTaskStatus>>,
    {
      platformUid: string;
      scheduleEventType: ScheduleEventType;
      jobStatus: JobStatus;
    }
  > = (props) => {
    const { platformUid, scheduleEventType, jobStatus } = props ?? {};

    return updatePlatformTaskStatus(platformUid, scheduleEventType, jobStatus);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlatformTaskStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePlatformTaskStatus>>
>;

export type UpdatePlatformTaskStatusMutationError = unknown;

export const useUpdatePlatformTaskStatus = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatformTaskStatus>>,
    TError,
    {
      platformUid: string;
      scheduleEventType: ScheduleEventType;
      jobStatus: JobStatus;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlatformTaskStatus>>,
  TError,
  {
    platformUid: string;
    scheduleEventType: ScheduleEventType;
    jobStatus: JobStatus;
  },
  TContext
> => {
  const mutationOptions = getUpdatePlatformTaskStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
export const processPlatformScheduledTaskSync = (
  platformUid: string,
  scheduleEventType: ScheduleEventType
) => {
  return customInstance<void>({
    url: `/operations/batch/processor/platform/${platformUid}/${scheduleEventType}/sync`,
    method: 'POST',
  });
};

export const getProcessPlatformScheduledTaskSyncMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processPlatformScheduledTaskSync>>,
    TError,
    { platformUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processPlatformScheduledTaskSync>>,
  TError,
  { platformUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processPlatformScheduledTaskSync>>,
    { platformUid: string; scheduleEventType: ScheduleEventType }
  > = (props) => {
    const { platformUid, scheduleEventType } = props ?? {};

    return processPlatformScheduledTaskSync(platformUid, scheduleEventType);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessPlatformScheduledTaskSyncMutationResult = NonNullable<
  Awaited<ReturnType<typeof processPlatformScheduledTaskSync>>
>;

export type ProcessPlatformScheduledTaskSyncMutationError = unknown;

export const useProcessPlatformScheduledTaskSync = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processPlatformScheduledTaskSync>>,
    TError,
    { platformUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processPlatformScheduledTaskSync>>,
  TError,
  { platformUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const mutationOptions =
    getProcessPlatformScheduledTaskSyncMutationOptions(options);

  return useMutation(mutationOptions);
};
