/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PlatformCustomerOnboardType =
  typeof PlatformCustomerOnboardType[keyof typeof PlatformCustomerOnboardType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformCustomerOnboardType = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  UNVERIFIED_VERIFIED_NOMINATED_ACCOUNT:
    'UNVERIFIED_VERIFIED_NOMINATED_ACCOUNT',
} as const;
