/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PlatformTransferType =
  typeof PlatformTransferType[keyof typeof PlatformTransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformTransferType = {
  POOLED: 'POOLED',
  INDIVIDUAL: 'INDIVIDUAL',
} as const;
