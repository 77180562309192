/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type RiskResult = typeof RiskResult[keyof typeof RiskResult];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RiskResult = {
  LOW_RISK: 'LOW_RISK',
  MEDIUM_RISK: 'MEDIUM_RISK',
  HIGH_RISK: 'HIGH_RISK',
  UNKNOWN: 'UNKNOWN',
  PROHIBITED: 'PROHIBITED',
} as const;
