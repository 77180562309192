/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InstructionStatus =
  typeof InstructionStatus[keyof typeof InstructionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InstructionStatus = {
  INBOUND_PLATFORM_PAYMENT_RECEIVED: 'INBOUND_PLATFORM_PAYMENT_RECEIVED',
  INBOUND_PLATFORM_PAYMENT_TRANSFER_INITIATED:
    'INBOUND_PLATFORM_PAYMENT_TRANSFER_INITIATED',
  INBOUND_PLATFORM_PAYMENT_TRANSFER_FAILED:
    'INBOUND_PLATFORM_PAYMENT_TRANSFER_FAILED',
  INBOUND_PLATFORM_PAYMENT_TRANSFER_PROCESSED:
    'INBOUND_PLATFORM_PAYMENT_TRANSFER_PROCESSED',
  OUTBOUND_BANK_PAYMENT_INITIATED: 'OUTBOUND_BANK_PAYMENT_INITIATED',
  OUTBOUND_BANK_PAYMENT_FAILED: 'OUTBOUND_BANK_PAYMENT_FAILED',
  OUTBOUND_BANK_PAYMENT_PROCESSED: 'OUTBOUND_BANK_PAYMENT_PROCESSED',
  BANK_DEPOSIT_INSTRUCTION_SENT: 'BANK_DEPOSIT_INSTRUCTION_SENT',
  INBOUND_BANK_PAYMENT_RECEIVED: 'INBOUND_BANK_PAYMENT_RECEIVED',
  INBOUND_BANK_PAYMENT_TRANSFER_INITIATED:
    'INBOUND_BANK_PAYMENT_TRANSFER_INITIATED',
  INBOUND_BANK_PAYMENT_TRANSFER_FAILED: 'INBOUND_BANK_PAYMENT_TRANSFER_FAILED',
  INBOUND_BANK_PAYMENT_TRANSFER_PROCESSED:
    'INBOUND_BANK_PAYMENT_TRANSFER_PROCESSED',
  OUTBOUND_PLATFORM_PAYMENT_INITIATED: 'OUTBOUND_PLATFORM_PAYMENT_INITIATED',
  OUTBOUND_PLATFORM_PAYMENT_PROCESSED: 'OUTBOUND_PLATFORM_PAYMENT_PROCESSED',
  OUTBOUND_PLATFORM_PAYMENT_FAILED: 'OUTBOUND_PLATFORM_PAYMENT_FAILED',
  BANK_WITHDRAWAL_INSTRUCTION_SENT: 'BANK_WITHDRAWAL_INSTRUCTION_SENT',
  INFORMED_ORDER_PROCESSED: 'INFORMED_ORDER_PROCESSED',
  REQUESTED: 'REQUESTED',
  CONFIRMED: 'CONFIRMED',
  CONFIRMED_SENT: 'CONFIRMED_SENT',
  PAYMENT_DUE_RAISED: 'PAYMENT_DUE_RAISED',
  REJECTED: 'REJECTED',
  REJECTED_SENT: 'REJECTED_SENT',
  CANCELLED: 'CANCELLED',
  CANCELLED_SENT: 'CANCELLED_SENT',
  EXPIRED: 'EXPIRED',
  ISSUED: 'ISSUED',
  INFORMED_DISTRIBUTION_PROCESSED: 'INFORMED_DISTRIBUTION_PROCESSED',
  COMPLETED: 'COMPLETED',
  COMPLETED_SENT: 'COMPLETED_SENT',
} as const;
