/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalDataSourceMatchType =
  typeof InternalDataSourceMatchType[keyof typeof InternalDataSourceMatchType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalDataSourceMatchType = {
  NAME_AND_ADDRESS: 'NAME_AND_ADDRESS',
  NAME_AND_DOB: 'NAME_AND_DOB',
} as const;
