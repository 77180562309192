/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type ComplianceInterventionStatus =
  typeof ComplianceInterventionStatus[keyof typeof ComplianceInterventionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceInterventionStatus = {
  AWAITING_INTERVENTION: 'AWAITING_INTERVENTION',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
} as const;
