/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalCurrencyCode =
  typeof InternalCurrencyCode[keyof typeof InternalCurrencyCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalCurrencyCode = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD',
  NZD: 'NZD',
} as const;
