/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageCustomerProductDistributionPaginatedRecord,
  BondsmithPageCustomerProductDistributionPaginatedResponse,
  BondsmithPageCustomerProductOrderConsolidatedPaginatedResponse,
  BondsmithPageCustomerProductOrderPaginatedRecord,
  BondsmithPageCustomerProductOrderPaginatedResponse,
  BondsmithPageCustomerTransferOrderPaginatedResponse,
  BondsmithPageCustomerUpdateResponse,
  CustomerProductOrderMoveRequest,
  FeeDistributionAdjustmentRequest,
  FetchPlatformProductsToMoveOrderToParams,
  InstructionCancellationResponseV11,
  ListConsolidatedCustomerProductOrdersParams,
  ListCustomerProductDistributionsByParams,
  ListCustomerProductDistributionsParams,
  ListCustomerProductOrdersByParams,
  ListCustomerProductOrdersParams,
  ListCustomerTransferOrdersParams,
  ListCustomerUpdatesParams,
  OrderFailureReasonResponse,
  OrderRejectionReasonResponse,
  OrderStateChangeResponse,
  PlatformProductMoveOrderResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listCustomerUpdates = (
  params?: ListCustomerUpdatesParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerUpdateResponse>({
    url: `/operations/own/instruction/customer-update`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerUpdatesQueryKey = (
  params?: ListCustomerUpdatesParams
) => {
  return [
    `/operations/own/instruction/customer-update`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerUpdatesQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerUpdates>>,
  TError = unknown
>(
  params?: ListCustomerUpdatesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerUpdates>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomerUpdatesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerUpdates>>
  > = ({ signal }) => listCustomerUpdates(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerUpdates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerUpdatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerUpdates>>
>;
export type ListCustomerUpdatesQueryError = unknown;

export const useListCustomerUpdates = <
  TData = Awaited<ReturnType<typeof listCustomerUpdates>>,
  TError = unknown
>(
  params?: ListCustomerUpdatesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerUpdates>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerUpdatesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCustomerProductDistributions = (
  params?: ListCustomerProductDistributionsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerProductDistributionPaginatedResponse>(
    {
      url: `/operations/own/instruction/distribution`,
      method: 'GET',
      params,
      signal,
    }
  );
};

export const getListCustomerProductDistributionsQueryKey = (
  params?: ListCustomerProductDistributionsParams
) => {
  return [
    `/operations/own/instruction/distribution`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerProductDistributionsQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerProductDistributions>>,
  TError = unknown
>(
  params?: ListCustomerProductDistributionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductDistributions>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListCustomerProductDistributionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerProductDistributions>>
  > = ({ signal }) => listCustomerProductDistributions(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerProductDistributions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerProductDistributionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerProductDistributions>>
>;
export type ListCustomerProductDistributionsQueryError = unknown;

export const useListCustomerProductDistributions = <
  TData = Awaited<ReturnType<typeof listCustomerProductDistributions>>,
  TError = unknown
>(
  params?: ListCustomerProductDistributionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductDistributions>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerProductDistributionsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const adjustFeeDistribution = (
  feeDistributionAdjustmentRequest: FeeDistributionAdjustmentRequest
) => {
  return customInstance<void>({
    url: `/operations/own/instruction/distribution/fee/adjust`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: feeDistributionAdjustmentRequest,
  });
};

export const getAdjustFeeDistributionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adjustFeeDistribution>>,
    TError,
    { data: FeeDistributionAdjustmentRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adjustFeeDistribution>>,
  TError,
  { data: FeeDistributionAdjustmentRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adjustFeeDistribution>>,
    { data: FeeDistributionAdjustmentRequest }
  > = (props) => {
    const { data } = props ?? {};

    return adjustFeeDistribution(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdjustFeeDistributionMutationResult = NonNullable<
  Awaited<ReturnType<typeof adjustFeeDistribution>>
>;
export type AdjustFeeDistributionMutationBody =
  FeeDistributionAdjustmentRequest;
export type AdjustFeeDistributionMutationError = unknown;

export const useAdjustFeeDistribution = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adjustFeeDistribution>>,
    TError,
    { data: FeeDistributionAdjustmentRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adjustFeeDistribution>>,
  TError,
  { data: FeeDistributionAdjustmentRequest },
  TContext
> => {
  const mutationOptions = getAdjustFeeDistributionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listCustomerProductDistributionsBy = (
  params?: ListCustomerProductDistributionsByParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerProductDistributionPaginatedRecord>(
    {
      url: `/operations/own/instruction/distribution/paginated`,
      method: 'GET',
      params,
      signal,
    }
  );
};

export const getListCustomerProductDistributionsByQueryKey = (
  params?: ListCustomerProductDistributionsByParams
) => {
  return [
    `/operations/own/instruction/distribution/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerProductDistributionsByQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerProductDistributionsBy>>,
  TError = unknown
>(
  params?: ListCustomerProductDistributionsByParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductDistributionsBy>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListCustomerProductDistributionsByQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerProductDistributionsBy>>
  > = ({ signal }) => listCustomerProductDistributionsBy(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerProductDistributionsBy>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerProductDistributionsByQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerProductDistributionsBy>>
>;
export type ListCustomerProductDistributionsByQueryError = unknown;

export const useListCustomerProductDistributionsBy = <
  TData = Awaited<ReturnType<typeof listCustomerProductDistributionsBy>>,
  TError = unknown
>(
  params?: ListCustomerProductDistributionsByParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductDistributionsBy>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerProductDistributionsByQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCustomerProductOrders = (
  params?: ListCustomerProductOrdersParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerProductOrderPaginatedResponse>({
    url: `/operations/own/instruction/order`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerProductOrdersQueryKey = (
  params?: ListCustomerProductOrdersParams
) => {
  return [
    `/operations/own/instruction/order`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerProductOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerProductOrders>>,
  TError = unknown
>(
  params?: ListCustomerProductOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductOrders>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomerProductOrdersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerProductOrders>>
  > = ({ signal }) => listCustomerProductOrders(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerProductOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerProductOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerProductOrders>>
>;
export type ListCustomerProductOrdersQueryError = unknown;

export const useListCustomerProductOrders = <
  TData = Awaited<ReturnType<typeof listCustomerProductOrders>>,
  TError = unknown
>(
  params?: ListCustomerProductOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductOrders>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerProductOrdersQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listConsolidatedCustomerProductOrders = (
  params?: ListConsolidatedCustomerProductOrdersParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerProductOrderConsolidatedPaginatedResponse>(
    {
      url: `/operations/own/instruction/order/consolidated`,
      method: 'GET',
      params,
      signal,
    }
  );
};

export const getListConsolidatedCustomerProductOrdersQueryKey = (
  params?: ListConsolidatedCustomerProductOrdersParams
) => {
  return [
    `/operations/own/instruction/order/consolidated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListConsolidatedCustomerProductOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof listConsolidatedCustomerProductOrders>>,
  TError = unknown
>(
  params?: ListConsolidatedCustomerProductOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listConsolidatedCustomerProductOrders>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListConsolidatedCustomerProductOrdersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listConsolidatedCustomerProductOrders>>
  > = ({ signal }) => listConsolidatedCustomerProductOrders(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listConsolidatedCustomerProductOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListConsolidatedCustomerProductOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listConsolidatedCustomerProductOrders>>
>;
export type ListConsolidatedCustomerProductOrdersQueryError = unknown;

export const useListConsolidatedCustomerProductOrders = <
  TData = Awaited<ReturnType<typeof listConsolidatedCustomerProductOrders>>,
  TError = unknown
>(
  params?: ListConsolidatedCustomerProductOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listConsolidatedCustomerProductOrders>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListConsolidatedCustomerProductOrdersQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCustomerProductOrdersBy = (
  params?: ListCustomerProductOrdersByParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerProductOrderPaginatedRecord>({
    url: `/operations/own/instruction/order/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerProductOrdersByQueryKey = (
  params?: ListCustomerProductOrdersByParams
) => {
  return [
    `/operations/own/instruction/order/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerProductOrdersByQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerProductOrdersBy>>,
  TError = unknown
>(
  params?: ListCustomerProductOrdersByParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductOrdersBy>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomerProductOrdersByQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerProductOrdersBy>>
  > = ({ signal }) => listCustomerProductOrdersBy(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerProductOrdersBy>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerProductOrdersByQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerProductOrdersBy>>
>;
export type ListCustomerProductOrdersByQueryError = unknown;

export const useListCustomerProductOrdersBy = <
  TData = Awaited<ReturnType<typeof listCustomerProductOrdersBy>>,
  TError = unknown
>(
  params?: ListCustomerProductOrdersByParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerProductOrdersBy>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerProductOrdersByQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCustomerTransferOrders = (
  params?: ListCustomerTransferOrdersParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerTransferOrderPaginatedResponse>({
    url: `/operations/own/instruction/order/transfer/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomerTransferOrdersQueryKey = (
  params?: ListCustomerTransferOrdersParams
) => {
  return [
    `/operations/own/instruction/order/transfer/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomerTransferOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomerTransferOrders>>,
  TError = unknown
>(
  params?: ListCustomerTransferOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerTransferOrders>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomerTransferOrdersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomerTransferOrders>>
  > = ({ signal }) => listCustomerTransferOrders(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomerTransferOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomerTransferOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomerTransferOrders>>
>;
export type ListCustomerTransferOrdersQueryError = unknown;

export const useListCustomerTransferOrders = <
  TData = Awaited<ReturnType<typeof listCustomerTransferOrders>>,
  TError = unknown
>(
  params?: ListCustomerTransferOrdersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomerTransferOrders>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomerTransferOrdersQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchFailureForOrderStaging = (
  orderStagingUid: string,
  signal?: AbortSignal
) => {
  return customInstance<OrderFailureReasonResponse[]>({
    url: `/operations/own/instruction/order/${orderStagingUid}/staging-failure`,
    method: 'GET',
    signal,
  });
};

export const getFetchFailureForOrderStagingQueryKey = (
  orderStagingUid: string
) => {
  return [
    `/operations/own/instruction/order/${orderStagingUid}/staging-failure`,
  ] as const;
};

export const getFetchFailureForOrderStagingQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchFailureForOrderStaging>>,
  TError = unknown
>(
  orderStagingUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchFailureForOrderStaging>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchFailureForOrderStagingQueryKey(orderStagingUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchFailureForOrderStaging>>
  > = ({ signal }) => fetchFailureForOrderStaging(orderStagingUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!orderStagingUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchFailureForOrderStaging>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchFailureForOrderStagingQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchFailureForOrderStaging>>
>;
export type FetchFailureForOrderStagingQueryError = unknown;

export const useFetchFailureForOrderStaging = <
  TData = Awaited<ReturnType<typeof fetchFailureForOrderStaging>>,
  TError = unknown
>(
  orderStagingUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchFailureForOrderStaging>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchFailureForOrderStagingQueryOptions(
    orderStagingUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchFailureForOrder = (
  orderUid: string,
  signal?: AbortSignal
) => {
  return customInstance<OrderFailureReasonResponse[]>({
    url: `/operations/own/instruction/order/${orderUid}/failure`,
    method: 'GET',
    signal,
  });
};

export const getFetchFailureForOrderQueryKey = (orderUid: string) => {
  return [`/operations/own/instruction/order/${orderUid}/failure`] as const;
};

export const getFetchFailureForOrderQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchFailureForOrder>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchFailureForOrder>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchFailureForOrderQueryKey(orderUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchFailureForOrder>>
  > = ({ signal }) => fetchFailureForOrder(orderUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!orderUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchFailureForOrder>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchFailureForOrderQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchFailureForOrder>>
>;
export type FetchFailureForOrderQueryError = unknown;

export const useFetchFailureForOrder = <
  TData = Awaited<ReturnType<typeof fetchFailureForOrder>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchFailureForOrder>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchFailureForOrderQueryOptions(orderUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const moveOrderToDifferentProduct = (
  orderUid: string,
  customerProductOrderMoveRequest: CustomerProductOrderMoveRequest
) => {
  return customInstance<void>({
    url: `/operations/own/instruction/order/${orderUid}/move`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: customerProductOrderMoveRequest,
  });
};

export const getMoveOrderToDifferentProductMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moveOrderToDifferentProduct>>,
    TError,
    { orderUid: string; data: CustomerProductOrderMoveRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof moveOrderToDifferentProduct>>,
  TError,
  { orderUid: string; data: CustomerProductOrderMoveRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof moveOrderToDifferentProduct>>,
    { orderUid: string; data: CustomerProductOrderMoveRequest }
  > = (props) => {
    const { orderUid, data } = props ?? {};

    return moveOrderToDifferentProduct(orderUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MoveOrderToDifferentProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof moveOrderToDifferentProduct>>
>;
export type MoveOrderToDifferentProductMutationBody =
  CustomerProductOrderMoveRequest;
export type MoveOrderToDifferentProductMutationError = unknown;

export const useMoveOrderToDifferentProduct = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof moveOrderToDifferentProduct>>,
    TError,
    { orderUid: string; data: CustomerProductOrderMoveRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof moveOrderToDifferentProduct>>,
  TError,
  { orderUid: string; data: CustomerProductOrderMoveRequest },
  TContext
> => {
  const mutationOptions =
    getMoveOrderToDifferentProductMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchPlatformProductsToMoveOrderTo = (
  orderUid: string,
  params?: FetchPlatformProductsToMoveOrderToParams,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductMoveOrderResponse[]>({
    url: `/operations/own/instruction/order/${orderUid}/products-to-move-to`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchPlatformProductsToMoveOrderToQueryKey = (
  orderUid: string,
  params?: FetchPlatformProductsToMoveOrderToParams
) => {
  return [
    `/operations/own/instruction/order/${orderUid}/products-to-move-to`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchPlatformProductsToMoveOrderToQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPlatformProductsToMoveOrderTo>>,
  TError = unknown
>(
  orderUid: string,
  params?: FetchPlatformProductsToMoveOrderToParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatformProductsToMoveOrderTo>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchPlatformProductsToMoveOrderToQueryKey(orderUid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchPlatformProductsToMoveOrderTo>>
  > = ({ signal }) =>
    fetchPlatformProductsToMoveOrderTo(orderUid, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!orderUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPlatformProductsToMoveOrderTo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPlatformProductsToMoveOrderToQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPlatformProductsToMoveOrderTo>>
>;
export type FetchPlatformProductsToMoveOrderToQueryError = unknown;

export const useFetchPlatformProductsToMoveOrderTo = <
  TData = Awaited<ReturnType<typeof fetchPlatformProductsToMoveOrderTo>>,
  TError = unknown
>(
  orderUid: string,
  params?: FetchPlatformProductsToMoveOrderToParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatformProductsToMoveOrderTo>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPlatformProductsToMoveOrderToQueryOptions(
    orderUid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listOrderRejectionReasons = (
  orderUid: string,
  signal?: AbortSignal
) => {
  return customInstance<OrderRejectionReasonResponse[]>({
    url: `/operations/own/instruction/order/${orderUid}/rejections`,
    method: 'GET',
    signal,
  });
};

export const getListOrderRejectionReasonsQueryKey = (orderUid: string) => {
  return [`/operations/own/instruction/order/${orderUid}/rejections`] as const;
};

export const getListOrderRejectionReasonsQueryOptions = <
  TData = Awaited<ReturnType<typeof listOrderRejectionReasons>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOrderRejectionReasons>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListOrderRejectionReasonsQueryKey(orderUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listOrderRejectionReasons>>
  > = ({ signal }) => listOrderRejectionReasons(orderUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!orderUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listOrderRejectionReasons>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOrderRejectionReasonsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listOrderRejectionReasons>>
>;
export type ListOrderRejectionReasonsQueryError = unknown;

export const useListOrderRejectionReasons = <
  TData = Awaited<ReturnType<typeof listOrderRejectionReasons>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOrderRejectionReasons>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOrderRejectionReasonsQueryOptions(
    orderUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getOrderStateChanges = (
  orderUid: string,
  signal?: AbortSignal
) => {
  return customInstance<OrderStateChangeResponse[]>({
    url: `/operations/own/instruction/order/${orderUid}/state`,
    method: 'GET',
    signal,
  });
};

export const getGetOrderStateChangesQueryKey = (orderUid: string) => {
  return [`/operations/own/instruction/order/${orderUid}/state`] as const;
};

export const getGetOrderStateChangesQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderStateChanges>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrderStateChanges>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOrderStateChangesQueryKey(orderUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrderStateChanges>>
  > = ({ signal }) => getOrderStateChanges(orderUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!orderUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderStateChanges>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderStateChangesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrderStateChanges>>
>;
export type GetOrderStateChangesQueryError = unknown;

export const useGetOrderStateChanges = <
  TData = Awaited<ReturnType<typeof getOrderStateChanges>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrderStateChanges>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrderStateChangesQueryOptions(orderUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const unrejectOrder = (orderUid: string) => {
  return customInstance<void>({
    url: `/operations/own/instruction/order/${orderUid}/unreject`,
    method: 'POST',
  });
};

export const getUnrejectOrderMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unrejectOrder>>,
    TError,
    { orderUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof unrejectOrder>>,
  TError,
  { orderUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof unrejectOrder>>,
    { orderUid: string }
  > = (props) => {
    const { orderUid } = props ?? {};

    return unrejectOrder(orderUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnrejectOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof unrejectOrder>>
>;

export type UnrejectOrderMutationError = unknown;

export const useUnrejectOrder = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unrejectOrder>>,
    TError,
    { orderUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof unrejectOrder>>,
  TError,
  { orderUid: string },
  TContext
> => {
  const mutationOptions = getUnrejectOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
export const cancelOrder = (platformUid: string, orderUid: string) => {
  return customInstance<InstructionCancellationResponseV11>({
    url: `/operations/own/instruction/platform/${platformUid}/order/${orderUid}`,
    method: 'DELETE',
  });
};

export const getCancelOrderMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelOrder>>,
    TError,
    { platformUid: string; orderUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelOrder>>,
  TError,
  { platformUid: string; orderUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelOrder>>,
    { platformUid: string; orderUid: string }
  > = (props) => {
    const { platformUid, orderUid } = props ?? {};

    return cancelOrder(platformUid, orderUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelOrder>>
>;

export type CancelOrderMutationError = unknown;

export const useCancelOrder = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelOrder>>,
    TError,
    { platformUid: string; orderUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof cancelOrder>>,
  TError,
  { platformUid: string; orderUid: string },
  TContext
> => {
  const mutationOptions = getCancelOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const resumeOrder = (orderUid: string) => {
  return customInstance<void>({
    url: `/operations/own/instruction/resume/order/${orderUid}`,
    method: 'PUT',
  });
};

export const getResumeOrderMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resumeOrder>>,
    TError,
    { orderUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof resumeOrder>>,
  TError,
  { orderUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resumeOrder>>,
    { orderUid: string }
  > = (props) => {
    const { orderUid } = props ?? {};

    return resumeOrder(orderUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResumeOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof resumeOrder>>
>;

export type ResumeOrderMutationError = unknown;

/**
 * @deprecated
 */
export const useResumeOrder = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resumeOrder>>,
    TError,
    { orderUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof resumeOrder>>,
  TError,
  { orderUid: string },
  TContext
> => {
  const mutationOptions = getResumeOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
