/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PaymentMatchingPartnerType =
  typeof PaymentMatchingPartnerType[keyof typeof PaymentMatchingPartnerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentMatchingPartnerType = {
  CUSTOMER: 'CUSTOMER',
  BANK: 'BANK',
  PLATFORM: 'PLATFORM',
  ALL: 'ALL',
} as const;
