/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type JobStatus = typeof JobStatus[keyof typeof JobStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JobStatus = {
  COMPLETED: 'COMPLETED',
  RUNNING: 'RUNNING',
  FAILED: 'FAILED',
  DISABLED: 'DISABLED',
} as const;
