/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageCustomerResponse,
  CustomerNoticeResponse,
  CustomerRequest,
  InstructionRequest,
  InstructionResponse,
  ListCustomersParams,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const expireOrder = (orderUid: string) => {
  return customInstance<void>({
    url: `/operations/platform/${orderUid}`,
    method: 'PATCH',
  });
};

export const getExpireOrderMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expireOrder>>,
    TError,
    { orderUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof expireOrder>>,
  TError,
  { orderUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof expireOrder>>,
    { orderUid: string }
  > = (props) => {
    const { orderUid } = props ?? {};

    return expireOrder(orderUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExpireOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof expireOrder>>
>;

export type ExpireOrderMutationError = unknown;

export const useExpireOrder = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof expireOrder>>,
    TError,
    { orderUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof expireOrder>>,
  TError,
  { orderUid: string },
  TContext
> => {
  const mutationOptions = getExpireOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchNoticeForOrder = (orderUid: string, signal?: AbortSignal) => {
  return customInstance<CustomerNoticeResponse>({
    url: `/operations/platform/${orderUid}/notice`,
    method: 'GET',
    signal,
  });
};

export const getFetchNoticeForOrderQueryKey = (orderUid: string) => {
  return [`/operations/platform/${orderUid}/notice`] as const;
};

export const getFetchNoticeForOrderQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchNoticeForOrder>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchNoticeForOrder>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchNoticeForOrderQueryKey(orderUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchNoticeForOrder>>
  > = ({ signal }) => fetchNoticeForOrder(orderUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!orderUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchNoticeForOrder>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchNoticeForOrderQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchNoticeForOrder>>
>;
export type FetchNoticeForOrderQueryError = unknown;

export const useFetchNoticeForOrder = <
  TData = Awaited<ReturnType<typeof fetchNoticeForOrder>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchNoticeForOrder>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchNoticeForOrderQueryOptions(orderUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listCustomers = (
  platformUid: string,
  params?: ListCustomersParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerResponse>({
    url: `/operations/platform/${platformUid}/customers`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomersQueryKey = (
  platformUid: string,
  params?: ListCustomersParams
) => {
  return [
    `/operations/platform/${platformUid}/customers`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomersQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomers>>,
  TError = unknown
>(
  platformUid: string,
  params?: ListCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomers>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomersQueryKey(platformUid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCustomers>>> = ({
    signal,
  }) => listCustomers(platformUid, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomers>>
>;
export type ListCustomersQueryError = unknown;

export const useListCustomers = <
  TData = Awaited<ReturnType<typeof listCustomers>>,
  TError = unknown
>(
  platformUid: string,
  params?: ListCustomersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomers>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomersQueryOptions(
    platformUid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createCustomerForPlatform = (
  platformUid: string,
  customerRequest: CustomerRequest
) => {
  return customInstance<string>({
    url: `/operations/platform/${platformUid}/customers`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: customerRequest,
  });
};

export const getCreateCustomerForPlatformMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCustomerForPlatform>>,
    TError,
    { platformUid: string; data: CustomerRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCustomerForPlatform>>,
  TError,
  { platformUid: string; data: CustomerRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCustomerForPlatform>>,
    { platformUid: string; data: CustomerRequest }
  > = (props) => {
    const { platformUid, data } = props ?? {};

    return createCustomerForPlatform(platformUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCustomerForPlatformMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCustomerForPlatform>>
>;
export type CreateCustomerForPlatformMutationBody = CustomerRequest;
export type CreateCustomerForPlatformMutationError = unknown;

export const useCreateCustomerForPlatform = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCustomerForPlatform>>,
    TError,
    { platformUid: string; data: CustomerRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCustomerForPlatform>>,
  TError,
  { platformUid: string; data: CustomerRequest },
  TContext
> => {
  const mutationOptions = getCreateCustomerForPlatformMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createOrder = (
  platformUid: string,
  customerUid: string,
  instructionRequest: InstructionRequest
) => {
  return customInstance<InstructionResponse>({
    url: `/operations/platform/${platformUid}/customers/${customerUid}/instructions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: instructionRequest,
  });
};

export const getCreateOrderMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrder>>,
    TError,
    { platformUid: string; customerUid: string; data: InstructionRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrder>>,
  TError,
  { platformUid: string; customerUid: string; data: InstructionRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrder>>,
    { platformUid: string; customerUid: string; data: InstructionRequest }
  > = (props) => {
    const { platformUid, customerUid, data } = props ?? {};

    return createOrder(platformUid, customerUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrder>>
>;
export type CreateOrderMutationBody = InstructionRequest;
export type CreateOrderMutationError = unknown;

export const useCreateOrder = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrder>>,
    TError,
    { platformUid: string; customerUid: string; data: InstructionRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createOrder>>,
  TError,
  { platformUid: string; customerUid: string; data: InstructionRequest },
  TContext
> => {
  const mutationOptions = getCreateOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
