/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation } from 'react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type { ConnectorType, IntegrityCheckResponse } from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const processCustomerAccountAwaitingOpening = () => {
  return customInstance<void>({
    url: `/operations/processor/accounts/awaiting-opening`,
    method: 'POST',
  });
};

export const getProcessCustomerAccountAwaitingOpeningMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processCustomerAccountAwaitingOpening>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processCustomerAccountAwaitingOpening>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processCustomerAccountAwaitingOpening>>,
    void
  > = () => {
    return processCustomerAccountAwaitingOpening();
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessCustomerAccountAwaitingOpeningMutationResult = NonNullable<
  Awaited<ReturnType<typeof processCustomerAccountAwaitingOpening>>
>;

export type ProcessCustomerAccountAwaitingOpeningMutationError = unknown;

export const useProcessCustomerAccountAwaitingOpening = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processCustomerAccountAwaitingOpening>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processCustomerAccountAwaitingOpening>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getProcessCustomerAccountAwaitingOpeningMutationOptions(options);

  return useMutation(mutationOptions);
};
export const outstandingPaymentCashAccount = () => {
  return customInstance<void>({
    url: `/operations/processor/cash-account/process-expected-payments`,
    method: 'POST',
  });
};

export const getOutstandingPaymentCashAccountMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outstandingPaymentCashAccount>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof outstandingPaymentCashAccount>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof outstandingPaymentCashAccount>>,
    void
  > = () => {
    return outstandingPaymentCashAccount();
  };

  return { mutationFn, ...mutationOptions };
};

export type OutstandingPaymentCashAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof outstandingPaymentCashAccount>>
>;

export type OutstandingPaymentCashAccountMutationError = unknown;

export const useOutstandingPaymentCashAccount = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof outstandingPaymentCashAccount>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof outstandingPaymentCashAccount>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getOutstandingPaymentCashAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const completeCashAccountWithdrawals = () => {
  return customInstance<void>({
    url: `/operations/processor/cash/complete`,
    method: 'POST',
  });
};

export const getCompleteCashAccountWithdrawalsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completeCashAccountWithdrawals>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof completeCashAccountWithdrawals>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof completeCashAccountWithdrawals>>,
    void
  > = () => {
    return completeCashAccountWithdrawals();
  };

  return { mutationFn, ...mutationOptions };
};

export type CompleteCashAccountWithdrawalsMutationResult = NonNullable<
  Awaited<ReturnType<typeof completeCashAccountWithdrawals>>
>;

export type CompleteCashAccountWithdrawalsMutationError = unknown;

export const useCompleteCashAccountWithdrawals = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completeCashAccountWithdrawals>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof completeCashAccountWithdrawals>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getCompleteCashAccountWithdrawalsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const runAllClearingIntegrityChecks = () => {
  return customInstance<IntegrityCheckResponse[]>({
    url: `/operations/processor/clearing-integrity-checks`,
    method: 'POST',
  });
};

export const getRunAllClearingIntegrityChecksMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllClearingIntegrityChecks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runAllClearingIntegrityChecks>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runAllClearingIntegrityChecks>>,
    void
  > = () => {
    return runAllClearingIntegrityChecks();
  };

  return { mutationFn, ...mutationOptions };
};

export type RunAllClearingIntegrityChecksMutationResult = NonNullable<
  Awaited<ReturnType<typeof runAllClearingIntegrityChecks>>
>;

export type RunAllClearingIntegrityChecksMutationError = unknown;

export const useRunAllClearingIntegrityChecks = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllClearingIntegrityChecks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runAllClearingIntegrityChecks>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getRunAllClearingIntegrityChecksMutationOptions(options);

  return useMutation(mutationOptions);
};
export const raisePaymentsDueForCustomers = () => {
  return customInstance<void>({
    url: `/operations/processor/customer/`,
    method: 'POST',
  });
};

export const getRaisePaymentsDueForCustomersMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof raisePaymentsDueForCustomers>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof raisePaymentsDueForCustomers>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof raisePaymentsDueForCustomers>>,
    void
  > = () => {
    return raisePaymentsDueForCustomers();
  };

  return { mutationFn, ...mutationOptions };
};

export type RaisePaymentsDueForCustomersMutationResult = NonNullable<
  Awaited<ReturnType<typeof raisePaymentsDueForCustomers>>
>;

export type RaisePaymentsDueForCustomersMutationError = unknown;

export const useRaisePaymentsDueForCustomers = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof raisePaymentsDueForCustomers>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof raisePaymentsDueForCustomers>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getRaisePaymentsDueForCustomersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const completeCustomerUpdates = () => {
  return customInstance<void>({
    url: `/operations/processor/customer/update/complete`,
    method: 'POST',
  });
};

export const getCompleteCustomerUpdatesMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completeCustomerUpdates>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof completeCustomerUpdates>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof completeCustomerUpdates>>,
    void
  > = () => {
    return completeCustomerUpdates();
  };

  return { mutationFn, ...mutationOptions };
};

export type CompleteCustomerUpdatesMutationResult = NonNullable<
  Awaited<ReturnType<typeof completeCustomerUpdates>>
>;

export type CompleteCustomerUpdatesMutationError = unknown;

export const useCompleteCustomerUpdates = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof completeCustomerUpdates>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof completeCustomerUpdates>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getCompleteCustomerUpdatesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const confirmCustomerUpdates = () => {
  return customInstance<void>({
    url: `/operations/processor/customer/update/confirm`,
    method: 'POST',
  });
};

export const getConfirmCustomerUpdatesMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmCustomerUpdates>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof confirmCustomerUpdates>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof confirmCustomerUpdates>>,
    void
  > = () => {
    return confirmCustomerUpdates();
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmCustomerUpdatesMutationResult = NonNullable<
  Awaited<ReturnType<typeof confirmCustomerUpdates>>
>;

export type ConfirmCustomerUpdatesMutationError = unknown;

export const useConfirmCustomerUpdates = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof confirmCustomerUpdates>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof confirmCustomerUpdates>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getConfirmCustomerUpdatesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const runAllDbIntegrityChecks = () => {
  return customInstance<IntegrityCheckResponse[]>({
    url: `/operations/processor/db-checks`,
    method: 'POST',
  });
};

export const getRunAllDbIntegrityChecksMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllDbIntegrityChecks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runAllDbIntegrityChecks>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runAllDbIntegrityChecks>>,
    void
  > = () => {
    return runAllDbIntegrityChecks();
  };

  return { mutationFn, ...mutationOptions };
};

export type RunAllDbIntegrityChecksMutationResult = NonNullable<
  Awaited<ReturnType<typeof runAllDbIntegrityChecks>>
>;

export type RunAllDbIntegrityChecksMutationError = unknown;

export const useRunAllDbIntegrityChecks = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllDbIntegrityChecks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runAllDbIntegrityChecks>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getRunAllDbIntegrityChecksMutationOptions(options);

  return useMutation(mutationOptions);
};
export const processCompletableDistributions = () => {
  return customInstance<void>({
    url: `/operations/processor/distribution/completable`,
    method: 'POST',
  });
};

export const getProcessCompletableDistributionsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processCompletableDistributions>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processCompletableDistributions>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processCompletableDistributions>>,
    void
  > = () => {
    return processCompletableDistributions();
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessCompletableDistributionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof processCompletableDistributions>>
>;

export type ProcessCompletableDistributionsMutationError = unknown;

export const useProcessCompletableDistributions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processCompletableDistributions>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processCompletableDistributions>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getProcessCompletableDistributionsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const automaticallyFundDeposits = () => {
  return customInstance<void>({
    url: `/operations/processor/fund/deposits`,
    method: 'POST',
  });
};

export const getAutomaticallyFundDepositsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automaticallyFundDeposits>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof automaticallyFundDeposits>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof automaticallyFundDeposits>>,
    void
  > = () => {
    return automaticallyFundDeposits();
  };

  return { mutationFn, ...mutationOptions };
};

export type AutomaticallyFundDepositsMutationResult = NonNullable<
  Awaited<ReturnType<typeof automaticallyFundDeposits>>
>;

export type AutomaticallyFundDepositsMutationError = unknown;

export const useAutomaticallyFundDeposits = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automaticallyFundDeposits>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof automaticallyFundDeposits>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getAutomaticallyFundDepositsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const automaticallyFundWithdrawals = () => {
  return customInstance<void>({
    url: `/operations/processor/fund/withdrawals`,
    method: 'POST',
  });
};

export const getAutomaticallyFundWithdrawalsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automaticallyFundWithdrawals>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof automaticallyFundWithdrawals>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof automaticallyFundWithdrawals>>,
    void
  > = () => {
    return automaticallyFundWithdrawals();
  };

  return { mutationFn, ...mutationOptions };
};

export type AutomaticallyFundWithdrawalsMutationResult = NonNullable<
  Awaited<ReturnType<typeof automaticallyFundWithdrawals>>
>;

export type AutomaticallyFundWithdrawalsMutationError = unknown;

export const useAutomaticallyFundWithdrawals = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automaticallyFundWithdrawals>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof automaticallyFundWithdrawals>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getAutomaticallyFundWithdrawalsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const runInterestAccruals = (bankUid: string, businessDate: string) => {
  return customInstance<void>({
    url: `/operations/processor/interest/accrue/bank/${bankUid}/date/${businessDate}`,
    method: 'POST',
  });
};

export const getRunInterestAccrualsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runInterestAccruals>>,
    TError,
    { bankUid: string; businessDate: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runInterestAccruals>>,
  TError,
  { bankUid: string; businessDate: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runInterestAccruals>>,
    { bankUid: string; businessDate: string }
  > = (props) => {
    const { bankUid, businessDate } = props ?? {};

    return runInterestAccruals(bankUid, businessDate);
  };

  return { mutationFn, ...mutationOptions };
};

export type RunInterestAccrualsMutationResult = NonNullable<
  Awaited<ReturnType<typeof runInterestAccruals>>
>;

export type RunInterestAccrualsMutationError = unknown;

export const useRunInterestAccruals = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runInterestAccruals>>,
    TError,
    { bankUid: string; businessDate: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runInterestAccruals>>,
  TError,
  { bankUid: string; businessDate: string },
  TContext
> => {
  const mutationOptions = getRunInterestAccrualsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const runInterestRealisations = (
  bankUid: string,
  businessDate: string
) => {
  return customInstance<void>({
    url: `/operations/processor/interest/realise/bank/${bankUid}/date/${businessDate}`,
    method: 'POST',
  });
};

export const getRunInterestRealisationsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runInterestRealisations>>,
    TError,
    { bankUid: string; businessDate: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runInterestRealisations>>,
  TError,
  { bankUid: string; businessDate: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runInterestRealisations>>,
    { bankUid: string; businessDate: string }
  > = (props) => {
    const { bankUid, businessDate } = props ?? {};

    return runInterestRealisations(bankUid, businessDate);
  };

  return { mutationFn, ...mutationOptions };
};

export type RunInterestRealisationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof runInterestRealisations>>
>;

export type RunInterestRealisationsMutationError = unknown;

export const useRunInterestRealisations = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runInterestRealisations>>,
    TError,
    { bankUid: string; businessDate: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runInterestRealisations>>,
  TError,
  { bankUid: string; businessDate: string },
  TContext
> => {
  const mutationOptions = getRunInterestRealisationsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const runAllInternalReconciliationChecks = () => {
  return customInstance<IntegrityCheckResponse[]>({
    url: `/operations/processor/internal-reconciliation`,
    method: 'POST',
  });
};

export const getRunAllInternalReconciliationChecksMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllInternalReconciliationChecks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runAllInternalReconciliationChecks>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runAllInternalReconciliationChecks>>,
    void
  > = () => {
    return runAllInternalReconciliationChecks();
  };

  return { mutationFn, ...mutationOptions };
};

export type RunAllInternalReconciliationChecksMutationResult = NonNullable<
  Awaited<ReturnType<typeof runAllInternalReconciliationChecks>>
>;

export type RunAllInternalReconciliationChecksMutationError = unknown;

export const useRunAllInternalReconciliationChecks = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllInternalReconciliationChecks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runAllInternalReconciliationChecks>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getRunAllInternalReconciliationChecksMutationOptions(options);

  return useMutation(mutationOptions);
};
export const processInternalTransfers = () => {
  return customInstance<void>({
    url: `/operations/processor/internal/transfers`,
    method: 'POST',
  });
};

export const getProcessInternalTransfersMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processInternalTransfers>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processInternalTransfers>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processInternalTransfers>>,
    void
  > = () => {
    return processInternalTransfers();
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessInternalTransfersMutationResult = NonNullable<
  Awaited<ReturnType<typeof processInternalTransfers>>
>;

export type ProcessInternalTransfersMutationError = unknown;

export const useProcessInternalTransfers = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processInternalTransfers>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processInternalTransfers>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getProcessInternalTransfersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const runAllOrderFlowIntegrityChecks = () => {
  return customInstance<IntegrityCheckResponse[]>({
    url: `/operations/processor/order-flow-checks`,
    method: 'POST',
  });
};

export const getRunAllOrderFlowIntegrityChecksMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllOrderFlowIntegrityChecks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runAllOrderFlowIntegrityChecks>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runAllOrderFlowIntegrityChecks>>,
    void
  > = () => {
    return runAllOrderFlowIntegrityChecks();
  };

  return { mutationFn, ...mutationOptions };
};

export type RunAllOrderFlowIntegrityChecksMutationResult = NonNullable<
  Awaited<ReturnType<typeof runAllOrderFlowIntegrityChecks>>
>;

export type RunAllOrderFlowIntegrityChecksMutationError = unknown;

export const useRunAllOrderFlowIntegrityChecks = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runAllOrderFlowIntegrityChecks>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runAllOrderFlowIntegrityChecks>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getRunAllOrderFlowIntegrityChecksMutationOptions(options);

  return useMutation(mutationOptions);
};
export const processCompletableOrders = () => {
  return customInstance<void>({
    url: `/operations/processor/order/completable`,
    method: 'POST',
  });
};

export const getProcessCompletableOrdersMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processCompletableOrders>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processCompletableOrders>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processCompletableOrders>>,
    void
  > = () => {
    return processCompletableOrders();
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessCompletableOrdersMutationResult = NonNullable<
  Awaited<ReturnType<typeof processCompletableOrders>>
>;

export type ProcessCompletableOrdersMutationError = unknown;

export const useProcessCompletableOrders = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processCompletableOrders>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processCompletableOrders>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getProcessCompletableOrdersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fundAdjustedOrders = () => {
  return customInstance<void>({
    url: `/operations/processor/order/fund`,
    method: 'POST',
  });
};

export const getFundAdjustedOrdersMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fundAdjustedOrders>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof fundAdjustedOrders>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fundAdjustedOrders>>,
    void
  > = () => {
    return fundAdjustedOrders();
  };

  return { mutationFn, ...mutationOptions };
};

export type FundAdjustedOrdersMutationResult = NonNullable<
  Awaited<ReturnType<typeof fundAdjustedOrders>>
>;

export type FundAdjustedOrdersMutationError = unknown;

export const useFundAdjustedOrders = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fundAdjustedOrders>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof fundAdjustedOrders>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getFundAdjustedOrdersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const adjustFullNoticeWithdrawals = () => {
  return customInstance<void>({
    url: `/operations/processor/order/notice/adjust`,
    method: 'POST',
  });
};

export const getAdjustFullNoticeWithdrawalsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adjustFullNoticeWithdrawals>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof adjustFullNoticeWithdrawals>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof adjustFullNoticeWithdrawals>>,
    void
  > = () => {
    return adjustFullNoticeWithdrawals();
  };

  return { mutationFn, ...mutationOptions };
};

export type AdjustFullNoticeWithdrawalsMutationResult = NonNullable<
  Awaited<ReturnType<typeof adjustFullNoticeWithdrawals>>
>;

export type AdjustFullNoticeWithdrawalsMutationError = unknown;

export const useAdjustFullNoticeWithdrawals = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof adjustFullNoticeWithdrawals>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof adjustFullNoticeWithdrawals>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getAdjustFullNoticeWithdrawalsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const processRequestedOrders = () => {
  return customInstance<void>({
    url: `/operations/processor/order/requested`,
    method: 'POST',
  });
};

export const getProcessRequestedOrdersMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processRequestedOrders>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processRequestedOrders>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processRequestedOrders>>,
    void
  > = () => {
    return processRequestedOrders();
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessRequestedOrdersMutationResult = NonNullable<
  Awaited<ReturnType<typeof processRequestedOrders>>
>;

export type ProcessRequestedOrdersMutationError = unknown;

export const useProcessRequestedOrders = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processRequestedOrders>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processRequestedOrders>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getProcessRequestedOrdersMutationOptions(options);

  return useMutation(mutationOptions);
};
export const refreshPartnerData = () => {
  return customInstance<void>({
    url: `/operations/processor/partners/refresh`,
    method: 'POST',
  });
};

export const getRefreshPartnerDataMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshPartnerData>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof refreshPartnerData>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof refreshPartnerData>>,
    void
  > = () => {
    return refreshPartnerData();
  };

  return { mutationFn, ...mutationOptions };
};

export type RefreshPartnerDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof refreshPartnerData>>
>;

export type RefreshPartnerDataMutationError = unknown;

export const useRefreshPartnerData = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof refreshPartnerData>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof refreshPartnerData>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getRefreshPartnerDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const sendRequestedPayments = () => {
  return customInstance<void>({
    url: `/operations/processor/payments/outgoing`,
    method: 'POST',
  });
};

export const getSendRequestedPaymentsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendRequestedPayments>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendRequestedPayments>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendRequestedPayments>>,
    void
  > = () => {
    return sendRequestedPayments();
  };

  return { mutationFn, ...mutationOptions };
};

export type SendRequestedPaymentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendRequestedPayments>>
>;

export type SendRequestedPaymentsMutationError = unknown;

export const useSendRequestedPayments = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendRequestedPayments>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendRequestedPayments>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getSendRequestedPaymentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const sendRequestedCashAccountPayments = () => {
  return customInstance<void>({
    url: `/operations/processor/payments/outgoing/cash`,
    method: 'POST',
  });
};

export const getSendRequestedCashAccountPaymentsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendRequestedCashAccountPayments>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendRequestedCashAccountPayments>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendRequestedCashAccountPayments>>,
    void
  > = () => {
    return sendRequestedCashAccountPayments();
  };

  return { mutationFn, ...mutationOptions };
};

export type SendRequestedCashAccountPaymentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendRequestedCashAccountPayments>>
>;

export type SendRequestedCashAccountPaymentsMutationError = unknown;

export const useSendRequestedCashAccountPayments = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendRequestedCashAccountPayments>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendRequestedCashAccountPayments>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getSendRequestedCashAccountPaymentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const processTermProductsAtMaturity = () => {
  return customInstance<void>({
    url: `/operations/processor/products/maturity`,
    method: 'POST',
  });
};

export const getProcessTermProductsAtMaturityMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processTermProductsAtMaturity>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof processTermProductsAtMaturity>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof processTermProductsAtMaturity>>,
    void
  > = () => {
    return processTermProductsAtMaturity();
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessTermProductsAtMaturityMutationResult = NonNullable<
  Awaited<ReturnType<typeof processTermProductsAtMaturity>>
>;

export type ProcessTermProductsAtMaturityMutationError = unknown;

export const useProcessTermProductsAtMaturity = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof processTermProductsAtMaturity>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof processTermProductsAtMaturity>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getProcessTermProductsAtMaturityMutationOptions(options);

  return useMutation(mutationOptions);
};
export const synchronizeProducts = () => {
  return customInstance<void>({
    url: `/operations/processor/products/synchronise`,
    method: 'POST',
  });
};

export const getSynchronizeProductsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof synchronizeProducts>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof synchronizeProducts>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof synchronizeProducts>>,
    void
  > = () => {
    return synchronizeProducts();
  };

  return { mutationFn, ...mutationOptions };
};

export type SynchronizeProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof synchronizeProducts>>
>;

export type SynchronizeProductsMutationError = unknown;

export const useSynchronizeProducts = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof synchronizeProducts>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof synchronizeProducts>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getSynchronizeProductsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const runExternalReconciliation = (connectorType: ConnectorType) => {
  return customInstance<void>({
    url: `/operations/processor/reconciliation/external/${connectorType}`,
    method: 'POST',
  });
};

export const getRunExternalReconciliationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runExternalReconciliation>>,
    TError,
    { connectorType: ConnectorType },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof runExternalReconciliation>>,
  TError,
  { connectorType: ConnectorType },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runExternalReconciliation>>,
    { connectorType: ConnectorType }
  > = (props) => {
    const { connectorType } = props ?? {};

    return runExternalReconciliation(connectorType);
  };

  return { mutationFn, ...mutationOptions };
};

export type RunExternalReconciliationMutationResult = NonNullable<
  Awaited<ReturnType<typeof runExternalReconciliation>>
>;

export type RunExternalReconciliationMutationError = unknown;

export const useRunExternalReconciliation = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runExternalReconciliation>>,
    TError,
    { connectorType: ConnectorType },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof runExternalReconciliation>>,
  TError,
  { connectorType: ConnectorType },
  TContext
> => {
  const mutationOptions = getRunExternalReconciliationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const recoverVirtualAccounts = () => {
  return customInstance<void>({
    url: `/operations/processor/recover-virtual-accounts`,
    method: 'POST',
  });
};

export const getRecoverVirtualAccountsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recoverVirtualAccounts>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof recoverVirtualAccounts>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recoverVirtualAccounts>>,
    void
  > = () => {
    return recoverVirtualAccounts();
  };

  return { mutationFn, ...mutationOptions };
};

export type RecoverVirtualAccountsMutationResult = NonNullable<
  Awaited<ReturnType<typeof recoverVirtualAccounts>>
>;

export type RecoverVirtualAccountsMutationError = unknown;

/**
 * @deprecated
 */
export const useRecoverVirtualAccounts = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recoverVirtualAccounts>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof recoverVirtualAccounts>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getRecoverVirtualAccountsMutationOptions(options);

  return useMutation(mutationOptions);
};
