/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankToFeesPaymentDueRequest,
  BondsmithPagePaymentManagementRequestPaginatedRecord,
  CustomerAddressChangeRequest,
  FetchPaymentManagementRequestsParams,
  FundExpectationRequest,
  FundingRecordSettlementRequest,
  FundingRecordSplitRequest,
  ManualTransferRequest,
  PaymentDueSplitRequest,
  PaymentLinkedOrderDetails,
  PaymentLinkedOrderResponse,
  PaymentManagementApprovalRequest,
  ReturnTransactionRequest,
  WithdrawalPaymentDueRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const updateCustomerAddress = (
  customerAddressChangeRequest: CustomerAddressChangeRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/customer/address`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: customerAddressChangeRequest,
  });
};

export const getUpdateCustomerAddressMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomerAddress>>,
    TError,
    { data: CustomerAddressChangeRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCustomerAddress>>,
  TError,
  { data: CustomerAddressChangeRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCustomerAddress>>,
    { data: CustomerAddressChangeRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateCustomerAddress(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCustomerAddressMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomerAddress>>
>;
export type UpdateCustomerAddressMutationBody = CustomerAddressChangeRequest;
export type UpdateCustomerAddressMutationError = unknown;

export const useUpdateCustomerAddress = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCustomerAddress>>,
    TError,
    { data: CustomerAddressChangeRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateCustomerAddress>>,
  TError,
  { data: CustomerAddressChangeRequest },
  TContext
> => {
  const mutationOptions = getUpdateCustomerAddressMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchLinkedOrdersForSplitting = (
  aggregatePaymentDueUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PaymentLinkedOrderResponse[]>({
    url: `/operations/own/payment/management/due/aggregate/${aggregatePaymentDueUid}/split`,
    method: 'GET',
    signal,
  });
};

export const getFetchLinkedOrdersForSplittingQueryKey = (
  aggregatePaymentDueUid: string
) => {
  return [
    `/operations/own/payment/management/due/aggregate/${aggregatePaymentDueUid}/split`,
  ] as const;
};

export const getFetchLinkedOrdersForSplittingQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting>>,
  TError = unknown
>(
  aggregatePaymentDueUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchLinkedOrdersForSplittingQueryKey(aggregatePaymentDueUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting>>
  > = ({ signal }) =>
    fetchLinkedOrdersForSplitting(aggregatePaymentDueUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!aggregatePaymentDueUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchLinkedOrdersForSplittingQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting>>
>;
export type FetchLinkedOrdersForSplittingQueryError = unknown;

export const useFetchLinkedOrdersForSplitting = <
  TData = Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting>>,
  TError = unknown
>(
  aggregatePaymentDueUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchLinkedOrdersForSplittingQueryOptions(
    aggregatePaymentDueUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const splitBankPaymentDue = (
  paymentDueSplitRequest: PaymentDueSplitRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/due/split/bank`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: paymentDueSplitRequest,
  });
};

export const getSplitBankPaymentDueMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof splitBankPaymentDue>>,
    TError,
    { data: PaymentDueSplitRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof splitBankPaymentDue>>,
  TError,
  { data: PaymentDueSplitRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof splitBankPaymentDue>>,
    { data: PaymentDueSplitRequest }
  > = (props) => {
    const { data } = props ?? {};

    return splitBankPaymentDue(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SplitBankPaymentDueMutationResult = NonNullable<
  Awaited<ReturnType<typeof splitBankPaymentDue>>
>;
export type SplitBankPaymentDueMutationBody = PaymentDueSplitRequest;
export type SplitBankPaymentDueMutationError = unknown;

export const useSplitBankPaymentDue = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof splitBankPaymentDue>>,
    TError,
    { data: PaymentDueSplitRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof splitBankPaymentDue>>,
  TError,
  { data: PaymentDueSplitRequest },
  TContext
> => {
  const mutationOptions = getSplitBankPaymentDueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const splitPlatformPaymentDue = (
  paymentDueSplitRequest: PaymentDueSplitRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/due/split/platform`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: paymentDueSplitRequest,
  });
};

export const getSplitPlatformPaymentDueMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof splitPlatformPaymentDue>>,
    TError,
    { data: PaymentDueSplitRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof splitPlatformPaymentDue>>,
  TError,
  { data: PaymentDueSplitRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof splitPlatformPaymentDue>>,
    { data: PaymentDueSplitRequest }
  > = (props) => {
    const { data } = props ?? {};

    return splitPlatformPaymentDue(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SplitPlatformPaymentDueMutationResult = NonNullable<
  Awaited<ReturnType<typeof splitPlatformPaymentDue>>
>;
export type SplitPlatformPaymentDueMutationBody = PaymentDueSplitRequest;
export type SplitPlatformPaymentDueMutationError = unknown;

export const useSplitPlatformPaymentDue = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof splitPlatformPaymentDue>>,
    TError,
    { data: PaymentDueSplitRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof splitPlatformPaymentDue>>,
  TError,
  { data: PaymentDueSplitRequest },
  TContext
> => {
  const mutationOptions = getSplitPlatformPaymentDueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchLinkedOrdersForSplitting1 = (
  paymentDueUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PaymentLinkedOrderDetails[]>({
    url: `/operations/own/payment/management/due/${paymentDueUid}/split`,
    method: 'GET',
    signal,
  });
};

export const getFetchLinkedOrdersForSplitting1QueryKey = (
  paymentDueUid: string
) => {
  return [
    `/operations/own/payment/management/due/${paymentDueUid}/split`,
  ] as const;
};

export const getFetchLinkedOrdersForSplitting1QueryOptions = <
  TData = Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting1>>,
  TError = unknown
>(
  paymentDueUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting1>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchLinkedOrdersForSplitting1QueryKey(paymentDueUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting1>>
  > = ({ signal }) => fetchLinkedOrdersForSplitting1(paymentDueUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!paymentDueUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchLinkedOrdersForSplitting1QueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting1>>
>;
export type FetchLinkedOrdersForSplitting1QueryError = unknown;

export const useFetchLinkedOrdersForSplitting1 = <
  TData = Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting1>>,
  TError = unknown
>(
  paymentDueUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchLinkedOrdersForSplitting1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchLinkedOrdersForSplitting1QueryOptions(
    paymentDueUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fundExpectation = (
  fundExpectationRequest: FundExpectationRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/fund/expectation`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundExpectationRequest,
  });
};

export const getFundExpectationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fundExpectation>>,
    TError,
    { data: FundExpectationRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof fundExpectation>>,
  TError,
  { data: FundExpectationRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fundExpectation>>,
    { data: FundExpectationRequest }
  > = (props) => {
    const { data } = props ?? {};

    return fundExpectation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type FundExpectationMutationResult = NonNullable<
  Awaited<ReturnType<typeof fundExpectation>>
>;
export type FundExpectationMutationBody = FundExpectationRequest;
export type FundExpectationMutationError = unknown;

export const useFundExpectation = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fundExpectation>>,
    TError,
    { data: FundExpectationRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof fundExpectation>>,
  TError,
  { data: FundExpectationRequest },
  TContext
> => {
  const mutationOptions = getFundExpectationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const settleFundingRecord = (
  fundingRecordSettlementRequest: FundingRecordSettlementRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/funding/settle`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundingRecordSettlementRequest,
  });
};

export const getSettleFundingRecordMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof settleFundingRecord>>,
    TError,
    { data: FundingRecordSettlementRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof settleFundingRecord>>,
  TError,
  { data: FundingRecordSettlementRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof settleFundingRecord>>,
    { data: FundingRecordSettlementRequest }
  > = (props) => {
    const { data } = props ?? {};

    return settleFundingRecord(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SettleFundingRecordMutationResult = NonNullable<
  Awaited<ReturnType<typeof settleFundingRecord>>
>;
export type SettleFundingRecordMutationBody = FundingRecordSettlementRequest;
export type SettleFundingRecordMutationError = unknown;

export const useSettleFundingRecord = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof settleFundingRecord>>,
    TError,
    { data: FundingRecordSettlementRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof settleFundingRecord>>,
  TError,
  { data: FundingRecordSettlementRequest },
  TContext
> => {
  const mutationOptions = getSettleFundingRecordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const splitFundingRecord = (
  fundingRecordSplitRequest: FundingRecordSplitRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/funding/split`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundingRecordSplitRequest,
  });
};

export const getSplitFundingRecordMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof splitFundingRecord>>,
    TError,
    { data: FundingRecordSplitRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof splitFundingRecord>>,
  TError,
  { data: FundingRecordSplitRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof splitFundingRecord>>,
    { data: FundingRecordSplitRequest }
  > = (props) => {
    const { data } = props ?? {};

    return splitFundingRecord(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SplitFundingRecordMutationResult = NonNullable<
  Awaited<ReturnType<typeof splitFundingRecord>>
>;
export type SplitFundingRecordMutationBody = FundingRecordSplitRequest;
export type SplitFundingRecordMutationError = unknown;

export const useSplitFundingRecord = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof splitFundingRecord>>,
    TError,
    { data: FundingRecordSplitRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof splitFundingRecord>>,
  TError,
  { data: FundingRecordSplitRequest },
  TContext
> => {
  const mutationOptions = getSplitFundingRecordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const approvePaymentManagementRequest = (
  paymentManagementApprovalRequest: PaymentManagementApprovalRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/payment/approval`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: paymentManagementApprovalRequest,
  });
};

export const getApprovePaymentManagementRequestMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvePaymentManagementRequest>>,
    TError,
    { data: PaymentManagementApprovalRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof approvePaymentManagementRequest>>,
  TError,
  { data: PaymentManagementApprovalRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approvePaymentManagementRequest>>,
    { data: PaymentManagementApprovalRequest }
  > = (props) => {
    const { data } = props ?? {};

    return approvePaymentManagementRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApprovePaymentManagementRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof approvePaymentManagementRequest>>
>;
export type ApprovePaymentManagementRequestMutationBody =
  PaymentManagementApprovalRequest;
export type ApprovePaymentManagementRequestMutationError = unknown;

export const useApprovePaymentManagementRequest = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approvePaymentManagementRequest>>,
    TError,
    { data: PaymentManagementApprovalRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof approvePaymentManagementRequest>>,
  TError,
  { data: PaymentManagementApprovalRequest },
  TContext
> => {
  const mutationOptions =
    getApprovePaymentManagementRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createCustomWithdrawalPaymentDue = (
  withdrawalPaymentDueRequest: WithdrawalPaymentDueRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/payment/due/custom`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: withdrawalPaymentDueRequest,
  });
};

export const getCreateCustomWithdrawalPaymentDueMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCustomWithdrawalPaymentDue>>,
    TError,
    { data: WithdrawalPaymentDueRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCustomWithdrawalPaymentDue>>,
  TError,
  { data: WithdrawalPaymentDueRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCustomWithdrawalPaymentDue>>,
    { data: WithdrawalPaymentDueRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createCustomWithdrawalPaymentDue(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCustomWithdrawalPaymentDueMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCustomWithdrawalPaymentDue>>
>;
export type CreateCustomWithdrawalPaymentDueMutationBody =
  WithdrawalPaymentDueRequest;
export type CreateCustomWithdrawalPaymentDueMutationError = unknown;

export const useCreateCustomWithdrawalPaymentDue = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCustomWithdrawalPaymentDue>>,
    TError,
    { data: WithdrawalPaymentDueRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCustomWithdrawalPaymentDue>>,
  TError,
  { data: WithdrawalPaymentDueRequest },
  TContext
> => {
  const mutationOptions =
    getCreateCustomWithdrawalPaymentDueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createBankToFeesPaymentDue = (
  bankToFeesPaymentDueRequest: BankToFeesPaymentDueRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/payment/fee/custom`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bankToFeesPaymentDueRequest,
  });
};

export const getCreateBankToFeesPaymentDueMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBankToFeesPaymentDue>>,
    TError,
    { data: BankToFeesPaymentDueRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createBankToFeesPaymentDue>>,
  TError,
  { data: BankToFeesPaymentDueRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createBankToFeesPaymentDue>>,
    { data: BankToFeesPaymentDueRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createBankToFeesPaymentDue(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateBankToFeesPaymentDueMutationResult = NonNullable<
  Awaited<ReturnType<typeof createBankToFeesPaymentDue>>
>;
export type CreateBankToFeesPaymentDueMutationBody =
  BankToFeesPaymentDueRequest;
export type CreateBankToFeesPaymentDueMutationError = unknown;

export const useCreateBankToFeesPaymentDue = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBankToFeesPaymentDue>>,
    TError,
    { data: BankToFeesPaymentDueRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createBankToFeesPaymentDue>>,
  TError,
  { data: BankToFeesPaymentDueRequest },
  TContext
> => {
  const mutationOptions = getCreateBankToFeesPaymentDueMutationOptions(options);

  return useMutation(mutationOptions);
};
export const rejectPaymentManagementRequest = (
  paymentManagementApprovalRequest: PaymentManagementApprovalRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/payment/rejection`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: paymentManagementApprovalRequest,
  });
};

export const getRejectPaymentManagementRequestMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectPaymentManagementRequest>>,
    TError,
    { data: PaymentManagementApprovalRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rejectPaymentManagementRequest>>,
  TError,
  { data: PaymentManagementApprovalRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rejectPaymentManagementRequest>>,
    { data: PaymentManagementApprovalRequest }
  > = (props) => {
    const { data } = props ?? {};

    return rejectPaymentManagementRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RejectPaymentManagementRequestMutationResult = NonNullable<
  Awaited<ReturnType<typeof rejectPaymentManagementRequest>>
>;
export type RejectPaymentManagementRequestMutationBody =
  PaymentManagementApprovalRequest;
export type RejectPaymentManagementRequestMutationError = unknown;

export const useRejectPaymentManagementRequest = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectPaymentManagementRequest>>,
    TError,
    { data: PaymentManagementApprovalRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof rejectPaymentManagementRequest>>,
  TError,
  { data: PaymentManagementApprovalRequest },
  TContext
> => {
  const mutationOptions =
    getRejectPaymentManagementRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchPaymentManagementRequests = (
  params?: FetchPaymentManagementRequestsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePaymentManagementRequestPaginatedRecord>({
    url: `/operations/own/payment/management/payment/request`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchPaymentManagementRequestsQueryKey = (
  params?: FetchPaymentManagementRequestsParams
) => {
  return [
    `/operations/own/payment/management/payment/request`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchPaymentManagementRequestsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPaymentManagementRequests>>,
  TError = unknown
>(
  params?: FetchPaymentManagementRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPaymentManagementRequests>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchPaymentManagementRequestsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchPaymentManagementRequests>>
  > = ({ signal }) => fetchPaymentManagementRequests(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPaymentManagementRequests>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPaymentManagementRequestsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPaymentManagementRequests>>
>;
export type FetchPaymentManagementRequestsQueryError = unknown;

export const useFetchPaymentManagementRequests = <
  TData = Awaited<ReturnType<typeof fetchPaymentManagementRequests>>,
  TError = unknown
>(
  params?: FetchPaymentManagementRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPaymentManagementRequests>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPaymentManagementRequestsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const returnTransaction = (
  returnTransactionRequest: ReturnTransactionRequest
) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/return`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: returnTransactionRequest,
  });
};

export const getReturnTransactionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof returnTransaction>>,
    TError,
    { data: ReturnTransactionRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof returnTransaction>>,
  TError,
  { data: ReturnTransactionRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof returnTransaction>>,
    { data: ReturnTransactionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return returnTransaction(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReturnTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof returnTransaction>>
>;
export type ReturnTransactionMutationBody = ReturnTransactionRequest;
export type ReturnTransactionMutationError = unknown;

export const useReturnTransaction = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof returnTransaction>>,
    TError,
    { data: ReturnTransactionRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof returnTransaction>>,
  TError,
  { data: ReturnTransactionRequest },
  TContext
> => {
  const mutationOptions = getReturnTransactionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const transferFunds = (manualTransferRequest: ManualTransferRequest) => {
  return customInstance<void>({
    url: `/operations/own/payment/management/transfer`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: manualTransferRequest,
  });
};

export const getTransferFundsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transferFunds>>,
    TError,
    { data: ManualTransferRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof transferFunds>>,
  TError,
  { data: ManualTransferRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof transferFunds>>,
    { data: ManualTransferRequest }
  > = (props) => {
    const { data } = props ?? {};

    return transferFunds(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TransferFundsMutationResult = NonNullable<
  Awaited<ReturnType<typeof transferFunds>>
>;
export type TransferFundsMutationBody = ManualTransferRequest;
export type TransferFundsMutationError = unknown;

export const useTransferFunds = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transferFunds>>,
    TError,
    { data: ManualTransferRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof transferFunds>>,
  TError,
  { data: ManualTransferRequest },
  TContext
> => {
  const mutationOptions = getTransferFundsMutationOptions(options);

  return useMutation(mutationOptions);
};
