/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PaymentDirection =
  typeof PaymentDirection[keyof typeof PaymentDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentDirection = {
  SAFEGUARD_PLATFORM: 'SAFEGUARD_PLATFORM',
  PLATFORM_SAFEGUARD: 'PLATFORM_SAFEGUARD',
  CASH_ACCOUNT_CUSTOMER: 'CASH_ACCOUNT_CUSTOMER',
  CUSTOMER_CASH_ACCOUNT: 'CUSTOMER_CASH_ACCOUNT',
  HUB_BANK: 'HUB_BANK',
  BANK_HUB: 'BANK_HUB',
  SAFEGUARD_HUB: 'SAFEGUARD_HUB',
  HUB_SAFEGUARD: 'HUB_SAFEGUARD',
  SAFEGUARD_CASH_ACCOUNT: 'SAFEGUARD_CASH_ACCOUNT',
  CASH_ACCOUNT_SAFEGUARD: 'CASH_ACCOUNT_SAFEGUARD',
  CASH_ACCOUNT_HUB: 'CASH_ACCOUNT_HUB',
  HUB_CASH_ACCOUNT: 'HUB_CASH_ACCOUNT',
  HUB_FEE: 'HUB_FEE',
  FUNDING_HUB: 'FUNDING_HUB',
  HUB_FUNDING: 'HUB_FUNDING',
  HUB_OPERATIONAL: 'HUB_OPERATIONAL',
  OPERATIONAL_HUB: 'OPERATIONAL_HUB',
  OPERATIONAL_SAFEGUARD: 'OPERATIONAL_SAFEGUARD',
  SAFEGUARD_OPERATIONAL: 'SAFEGUARD_OPERATIONAL',
  OPERATIONAL_CASH_ACCOUNT: 'OPERATIONAL_CASH_ACCOUNT',
  CASH_ACCOUNT_OPERATIONAL: 'CASH_ACCOUNT_OPERATIONAL',
  FROM_OPERATIONAL: 'FROM_OPERATIONAL',
  TO_OPERATIONAL: 'TO_OPERATIONAL',
  NONE: 'NONE',
} as const;
