/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankStatisticsResponse,
  ProductDistributionResponse,
  StatisticsTermVolumeResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getBankProductDistributions = (
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<ProductDistributionResponse[]>({
    url: `/operations/banks/statistics/${bankUid}/chart/product/statistics`,
    method: 'GET',
    signal,
  });
};

export const getGetBankProductDistributionsQueryKey = (bankUid: string) => {
  return [
    `/operations/banks/statistics/${bankUid}/chart/product/statistics`,
  ] as const;
};

export const getGetBankProductDistributionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getBankProductDistributions>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBankProductDistributions>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBankProductDistributionsQueryKey(bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBankProductDistributions>>
  > = ({ signal }) => getBankProductDistributions(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBankProductDistributions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBankProductDistributionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBankProductDistributions>>
>;
export type GetBankProductDistributionsQueryError = unknown;

export const useGetBankProductDistributions = <
  TData = Awaited<ReturnType<typeof getBankProductDistributions>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBankProductDistributions>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBankProductDistributionsQueryOptions(
    bankUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBankStatistics = (bankUid: string, signal?: AbortSignal) => {
  return customInstance<BankStatisticsResponse>({
    url: `/operations/banks/statistics/${bankUid}/statistics`,
    method: 'GET',
    signal,
  });
};

export const getGetBankStatisticsQueryKey = (bankUid: string) => {
  return [`/operations/banks/statistics/${bankUid}/statistics`] as const;
};

export const getGetBankStatisticsQueryOptions = <
  TData = Awaited<ReturnType<typeof getBankStatistics>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBankStatistics>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBankStatisticsQueryKey(bankUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankStatistics>>> =
    ({ signal }) => getBankStatistics(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBankStatistics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBankStatisticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBankStatistics>>
>;
export type GetBankStatisticsQueryError = unknown;

export const useGetBankStatistics = <
  TData = Awaited<ReturnType<typeof getBankStatistics>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBankStatistics>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBankStatisticsQueryOptions(bankUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBankTermVolume = (bankUid: string, signal?: AbortSignal) => {
  return customInstance<StatisticsTermVolumeResponse>({
    url: `/operations/banks/statistics/${bankUid}/volume`,
    method: 'GET',
    signal,
  });
};

export const getGetBankTermVolumeQueryKey = (bankUid: string) => {
  return [`/operations/banks/statistics/${bankUid}/volume`] as const;
};

export const getGetBankTermVolumeQueryOptions = <
  TData = Awaited<ReturnType<typeof getBankTermVolume>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBankTermVolume>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBankTermVolumeQueryKey(bankUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankTermVolume>>> =
    ({ signal }) => getBankTermVolume(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBankTermVolume>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBankTermVolumeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBankTermVolume>>
>;
export type GetBankTermVolumeQueryError = unknown;

export const useGetBankTermVolume = <
  TData = Awaited<ReturnType<typeof getBankTermVolume>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBankTermVolume>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBankTermVolumeQueryOptions(bankUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
