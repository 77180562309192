/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PaymentManagementRequestPaginatedProperty =
  typeof PaymentManagementRequestPaginatedProperty[keyof typeof PaymentManagementRequestPaginatedProperty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentManagementRequestPaginatedProperty = {
  REQUESTED_AT: 'REQUESTED_AT',
  APPROVED_AT: 'APPROVED_AT',
} as const;
