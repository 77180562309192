/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type SuperState = typeof SuperState[keyof typeof SuperState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SuperState = {
  PENDING: 'PENDING',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  CANCELLED: 'CANCELLED',
  IN_PROGRESS: 'IN_PROGRESS',
  SETTLING: 'SETTLING',
  COMPLETED: 'COMPLETED',
} as const;
