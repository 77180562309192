/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  PlatformDepositsStatisticResponse,
  ProductDistributionResponse,
  StatisticsTermVolumeResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getPlatformProductDistributions = (
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<ProductDistributionResponse[]>({
    url: `/operations/platforms/statistics/${platformUid}/chart/product/statistics`,
    method: 'GET',
    signal,
  });
};

export const getGetPlatformProductDistributionsQueryKey = (
  platformUid: string
) => {
  return [
    `/operations/platforms/statistics/${platformUid}/chart/product/statistics`,
  ] as const;
};

export const getGetPlatformProductDistributionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformProductDistributions>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformProductDistributions>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPlatformProductDistributionsQueryKey(platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlatformProductDistributions>>
  > = ({ signal }) => getPlatformProductDistributions(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformProductDistributions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlatformProductDistributionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlatformProductDistributions>>
>;
export type GetPlatformProductDistributionsQueryError = unknown;

export const useGetPlatformProductDistributions = <
  TData = Awaited<ReturnType<typeof getPlatformProductDistributions>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformProductDistributions>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformProductDistributionsQueryOptions(
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPlatformDepositsStatistics = (
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformDepositsStatisticResponse>({
    url: `/operations/platforms/statistics/${platformUid}/platform/deposits/statistics`,
    method: 'GET',
    signal,
  });
};

export const getGetPlatformDepositsStatisticsQueryKey = (
  platformUid: string
) => {
  return [
    `/operations/platforms/statistics/${platformUid}/platform/deposits/statistics`,
  ] as const;
};

export const getGetPlatformDepositsStatisticsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformDepositsStatistics>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformDepositsStatistics>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPlatformDepositsStatisticsQueryKey(platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlatformDepositsStatistics>>
  > = ({ signal }) => getPlatformDepositsStatistics(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformDepositsStatistics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlatformDepositsStatisticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlatformDepositsStatistics>>
>;
export type GetPlatformDepositsStatisticsQueryError = unknown;

export const useGetPlatformDepositsStatistics = <
  TData = Awaited<ReturnType<typeof getPlatformDepositsStatistics>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformDepositsStatistics>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformDepositsStatisticsQueryOptions(
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPlatformTermVolume = (
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<StatisticsTermVolumeResponse>({
    url: `/operations/platforms/statistics/${platformUid}/volume`,
    method: 'GET',
    signal,
  });
};

export const getGetPlatformTermVolumeQueryKey = (platformUid: string) => {
  return [`/operations/platforms/statistics/${platformUid}/volume`] as const;
};

export const getGetPlatformTermVolumeQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformTermVolume>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformTermVolume>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlatformTermVolumeQueryKey(platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlatformTermVolume>>
  > = ({ signal }) => getPlatformTermVolume(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformTermVolume>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlatformTermVolumeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlatformTermVolume>>
>;
export type GetPlatformTermVolumeQueryError = unknown;

export const useGetPlatformTermVolume = <
  TData = Awaited<ReturnType<typeof getPlatformTermVolume>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformTermVolume>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformTermVolumeQueryOptions(
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
