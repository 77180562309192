/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PaymentDueStatus =
  typeof PaymentDueStatus[keyof typeof PaymentDueStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentDueStatus = {
  EXPECTED: 'EXPECTED',
  SENT: 'SENT',
  PRODUCED: 'PRODUCED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
} as const;
