/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BatchEventResponse,
  BondsmithPageBatchEventPaginatedRecord,
  FetchEvents1Params,
  FetchEventsParams,
  FetchPlatformOrderBatchesBetweenDatesParams,
  ListInstructionBatchesParams,
  OrderStagingRedriveRequest,
  OrderStagingRequest,
  OrderStagingResponse,
  PlatformOrderBatchResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const fetchEvents = (
  params?: FetchEventsParams,
  signal?: AbortSignal
) => {
  return customInstance<BatchEventResponse[]>({
    url: `/operations/batch/events`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchEventsQueryKey = (params?: FetchEventsParams) => {
  return [`/operations/batch/events`, ...(params ? [params] : [])] as const;
};

export const getFetchEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchEvents>>,
  TError = unknown
>(
  params?: FetchEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchEvents>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchEventsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchEvents>>> = ({
    signal,
  }) => fetchEvents(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchEvents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchEvents>>
>;
export type FetchEventsQueryError = unknown;

export const useFetchEvents = <
  TData = Awaited<ReturnType<typeof fetchEvents>>,
  TError = unknown
>(
  params?: FetchEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchEvents>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchEvents1 = (
  params?: FetchEvents1Params,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageBatchEventPaginatedRecord>({
    url: `/operations/batch/events/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchEvents1QueryKey = (params?: FetchEvents1Params) => {
  return [
    `/operations/batch/events/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchEvents1QueryOptions = <
  TData = Awaited<ReturnType<typeof fetchEvents1>>,
  TError = unknown
>(
  params?: FetchEvents1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchEvents1>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchEvents1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchEvents1>>> = ({
    signal,
  }) => fetchEvents1(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchEvents1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchEvents1QueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchEvents1>>
>;
export type FetchEvents1QueryError = unknown;

export const useFetchEvents1 = <
  TData = Awaited<ReturnType<typeof fetchEvents1>>,
  TError = unknown
>(
  params?: FetchEvents1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchEvents1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchEvents1QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchBatchEventFileURL = (
  batchUid: string,
  signal?: AbortSignal
) => {
  return customInstance<string>({
    url: `/operations/batch/events/${batchUid}/file`,
    method: 'GET',
    signal,
  });
};

export const getFetchBatchEventFileURLQueryKey = (batchUid: string) => {
  return [`/operations/batch/events/${batchUid}/file`] as const;
};

export const getFetchBatchEventFileURLQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
  TError = unknown
>(
  batchUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchBatchEventFileURLQueryKey(batchUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchBatchEventFileURL>>
  > = ({ signal }) => fetchBatchEventFileURL(batchUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!batchUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchBatchEventFileURLQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchBatchEventFileURL>>
>;
export type FetchBatchEventFileURLQueryError = unknown;

export const useFetchBatchEventFileURL = <
  TData = Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
  TError = unknown
>(
  batchUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchBatchEventFileURLQueryOptions(batchUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getOrderStagingEntry = (
  orderStagingUid: string,
  signal?: AbortSignal
) => {
  return customInstance<OrderStagingResponse>({
    url: `/operations/own/batch/order/${orderStagingUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetOrderStagingEntryQueryKey = (orderStagingUid: string) => {
  return [`/operations/own/batch/order/${orderStagingUid}`] as const;
};

export const getGetOrderStagingEntryQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderStagingEntry>>,
  TError = unknown
>(
  orderStagingUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrderStagingEntry>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetOrderStagingEntryQueryKey(orderStagingUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getOrderStagingEntry>>
  > = ({ signal }) => getOrderStagingEntry(orderStagingUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!orderStagingUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderStagingEntry>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetOrderStagingEntryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrderStagingEntry>>
>;
export type GetOrderStagingEntryQueryError = unknown;

export const useGetOrderStagingEntry = <
  TData = Awaited<ReturnType<typeof getOrderStagingEntry>>,
  TError = unknown
>(
  orderStagingUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrderStagingEntry>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrderStagingEntryQueryOptions(
    orderStagingUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateOrderStagingEntry = (
  orderStagingUid: string,
  orderStagingRequest: OrderStagingRequest
) => {
  return customInstance<void>({
    url: `/operations/own/batch/order/${orderStagingUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: orderStagingRequest,
  });
};

export const getUpdateOrderStagingEntryMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrderStagingEntry>>,
    TError,
    { orderStagingUid: string; data: OrderStagingRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateOrderStagingEntry>>,
  TError,
  { orderStagingUid: string; data: OrderStagingRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateOrderStagingEntry>>,
    { orderStagingUid: string; data: OrderStagingRequest }
  > = (props) => {
    const { orderStagingUid, data } = props ?? {};

    return updateOrderStagingEntry(orderStagingUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateOrderStagingEntryMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateOrderStagingEntry>>
>;
export type UpdateOrderStagingEntryMutationBody = OrderStagingRequest;
export type UpdateOrderStagingEntryMutationError = unknown;

export const useUpdateOrderStagingEntry = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrderStagingEntry>>,
    TError,
    { orderStagingUid: string; data: OrderStagingRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateOrderStagingEntry>>,
  TError,
  { orderStagingUid: string; data: OrderStagingRequest },
  TContext
> => {
  const mutationOptions = getUpdateOrderStagingEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const acknowledgeOrderFailure = (orderStagingUid: string) => {
  return customInstance<void>({
    url: `/operations/own/batch/order/${orderStagingUid}/acknowledge-fail`,
    method: 'POST',
  });
};

export const getAcknowledgeOrderFailureMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acknowledgeOrderFailure>>,
    TError,
    { orderStagingUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acknowledgeOrderFailure>>,
  TError,
  { orderStagingUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acknowledgeOrderFailure>>,
    { orderStagingUid: string }
  > = (props) => {
    const { orderStagingUid } = props ?? {};

    return acknowledgeOrderFailure(orderStagingUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcknowledgeOrderFailureMutationResult = NonNullable<
  Awaited<ReturnType<typeof acknowledgeOrderFailure>>
>;

export type AcknowledgeOrderFailureMutationError = unknown;

export const useAcknowledgeOrderFailure = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acknowledgeOrderFailure>>,
    TError,
    { orderStagingUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof acknowledgeOrderFailure>>,
  TError,
  { orderStagingUid: string },
  TContext
> => {
  const mutationOptions = getAcknowledgeOrderFailureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const batchOrdersForPlatform = (
  platformUid: string,
  orderStagingRedriveRequest: OrderStagingRedriveRequest
) => {
  return customInstance<void>({
    url: `/operations/own/batch/publish/${platformUid}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: orderStagingRedriveRequest,
  });
};

export const getBatchOrdersForPlatformMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof batchOrdersForPlatform>>,
    TError,
    { platformUid: string; data: OrderStagingRedriveRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof batchOrdersForPlatform>>,
  TError,
  { platformUid: string; data: OrderStagingRedriveRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof batchOrdersForPlatform>>,
    { platformUid: string; data: OrderStagingRedriveRequest }
  > = (props) => {
    const { platformUid, data } = props ?? {};

    return batchOrdersForPlatform(platformUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BatchOrdersForPlatformMutationResult = NonNullable<
  Awaited<ReturnType<typeof batchOrdersForPlatform>>
>;
export type BatchOrdersForPlatformMutationBody = OrderStagingRedriveRequest;
export type BatchOrdersForPlatformMutationError = unknown;

export const useBatchOrdersForPlatform = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof batchOrdersForPlatform>>,
    TError,
    { platformUid: string; data: OrderStagingRedriveRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof batchOrdersForPlatform>>,
  TError,
  { platformUid: string; data: OrderStagingRedriveRequest },
  TContext
> => {
  const mutationOptions = getBatchOrdersForPlatformMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchPlatformOrderBatchesBetweenDates = (
  params: FetchPlatformOrderBatchesBetweenDatesParams,
  signal?: AbortSignal
) => {
  return customInstance<PlatformOrderBatchResponse[]>({
    url: `/operations/platform/batch/date`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchPlatformOrderBatchesBetweenDatesQueryKey = (
  params: FetchPlatformOrderBatchesBetweenDatesParams
) => {
  return [
    `/operations/platform/batch/date`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchPlatformOrderBatchesBetweenDatesQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPlatformOrderBatchesBetweenDates>>,
  TError = unknown
>(
  params: FetchPlatformOrderBatchesBetweenDatesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatformOrderBatchesBetweenDates>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchPlatformOrderBatchesBetweenDatesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchPlatformOrderBatchesBetweenDates>>
  > = ({ signal }) => fetchPlatformOrderBatchesBetweenDates(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPlatformOrderBatchesBetweenDates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPlatformOrderBatchesBetweenDatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPlatformOrderBatchesBetweenDates>>
>;
export type FetchPlatformOrderBatchesBetweenDatesQueryError = unknown;

export const useFetchPlatformOrderBatchesBetweenDates = <
  TData = Awaited<ReturnType<typeof fetchPlatformOrderBatchesBetweenDates>>,
  TError = unknown
>(
  params: FetchPlatformOrderBatchesBetweenDatesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatformOrderBatchesBetweenDates>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPlatformOrderBatchesBetweenDatesQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listInstructionBatches = (
  params?: ListInstructionBatchesParams,
  signal?: AbortSignal
) => {
  return customInstance<PlatformOrderBatchResponse[]>({
    url: `/operations/platform/batch/instructions`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListInstructionBatchesQueryKey = (
  params?: ListInstructionBatchesParams
) => {
  return [
    `/operations/platform/batch/instructions`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListInstructionBatchesQueryOptions = <
  TData = Awaited<ReturnType<typeof listInstructionBatches>>,
  TError = unknown
>(
  params?: ListInstructionBatchesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listInstructionBatches>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListInstructionBatchesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listInstructionBatches>>
  > = ({ signal }) => listInstructionBatches(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listInstructionBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListInstructionBatchesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listInstructionBatches>>
>;
export type ListInstructionBatchesQueryError = unknown;

export const useListInstructionBatches = <
  TData = Awaited<ReturnType<typeof listInstructionBatches>>,
  TError = unknown
>(
  params?: ListInstructionBatchesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listInstructionBatches>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListInstructionBatchesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchPlatformOrderBatchByOrderUid = (
  orderUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformOrderBatchResponse>({
    url: `/operations/platform/batch/order/${orderUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchPlatformOrderBatchByOrderUidQueryKey = (
  orderUid: string
) => {
  return [`/operations/platform/batch/order/${orderUid}`] as const;
};

export const getFetchPlatformOrderBatchByOrderUidQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPlatformOrderBatchByOrderUid>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatformOrderBatchByOrderUid>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchPlatformOrderBatchByOrderUidQueryKey(orderUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchPlatformOrderBatchByOrderUid>>
  > = ({ signal }) => fetchPlatformOrderBatchByOrderUid(orderUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!orderUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPlatformOrderBatchByOrderUid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPlatformOrderBatchByOrderUidQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPlatformOrderBatchByOrderUid>>
>;
export type FetchPlatformOrderBatchByOrderUidQueryError = unknown;

export const useFetchPlatformOrderBatchByOrderUid = <
  TData = Awaited<ReturnType<typeof fetchPlatformOrderBatchByOrderUid>>,
  TError = unknown
>(
  orderUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatformOrderBatchByOrderUid>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPlatformOrderBatchByOrderUidQueryOptions(
    orderUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchOrderBatchesByPlatform = (
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformOrderBatchResponse[]>({
    url: `/operations/platform/batch/platform/${platformUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchOrderBatchesByPlatformQueryKey = (platformUid: string) => {
  return [`/operations/platform/batch/platform/${platformUid}`] as const;
};

export const getFetchOrderBatchesByPlatformQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchOrderBatchesByPlatform>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchOrderBatchesByPlatform>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchOrderBatchesByPlatformQueryKey(platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchOrderBatchesByPlatform>>
  > = ({ signal }) => fetchOrderBatchesByPlatform(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchOrderBatchesByPlatform>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchOrderBatchesByPlatformQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchOrderBatchesByPlatform>>
>;
export type FetchOrderBatchesByPlatformQueryError = unknown;

export const useFetchOrderBatchesByPlatform = <
  TData = Awaited<ReturnType<typeof fetchOrderBatchesByPlatform>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchOrderBatchesByPlatform>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchOrderBatchesByPlatformQueryOptions(
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchPlatformOrderBatchDetails = (
  instructionBatchUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformOrderBatchResponse>({
    url: `/operations/platform/batch/${instructionBatchUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchPlatformOrderBatchDetailsQueryKey = (
  instructionBatchUid: string
) => {
  return [`/operations/platform/batch/${instructionBatchUid}`] as const;
};

export const getFetchPlatformOrderBatchDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPlatformOrderBatchDetails>>,
  TError = unknown
>(
  instructionBatchUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatformOrderBatchDetails>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchPlatformOrderBatchDetailsQueryKey(instructionBatchUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchPlatformOrderBatchDetails>>
  > = ({ signal }) =>
    fetchPlatformOrderBatchDetails(instructionBatchUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!instructionBatchUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPlatformOrderBatchDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPlatformOrderBatchDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPlatformOrderBatchDetails>>
>;
export type FetchPlatformOrderBatchDetailsQueryError = unknown;

export const useFetchPlatformOrderBatchDetails = <
  TData = Awaited<ReturnType<typeof fetchPlatformOrderBatchDetails>>,
  TError = unknown
>(
  instructionBatchUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatformOrderBatchDetails>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPlatformOrderBatchDetailsQueryOptions(
    instructionBatchUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
